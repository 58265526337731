import Vue from 'vue'

import { formatCurrency } from './currency'

export const km = function(value) {
    if (value) {
        return `${value.toFixed(2)} km`
    }
    return ''
}

export const cityTax = function(value, type, currency = 'EUR') {
    switch (type) {
        case 'P': {
            return `${value.toFixed(2)}%`
        }
        case 'V': {
            const _value = formatCurrency(value, currency)
            return `${_value} per pax/night`
        }
        case 'R': {
            const _value = formatCurrency(value, currency)
            return `${_value} per room/night`
        }
    }
    return ''
}

Vue.prototype.$fmt = {
    km,
    cityTax,
}

export default {
    km,
    cityTax,
}
