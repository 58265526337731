<template>
    <div
        class="tab-page"
        v-show="isActive"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    inject: ['tabs', 'active'],
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            required: true,
        },
        permissions: {
            type: [String, Array],
            default: undefined,
        },
        errors: {
            type: Object,
            default: undefined,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        badge: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        isActive() {
            return this.active === this.label
        },
        href() {
            return `#${this.label.toLowerCase().replace(/ /g, '-')}`
        },
        available() {
            if (this.permissions) {
                if (Array.isArray(this.permissions)) {
                    return this.permissions
                        .map((perm) => this.hasPerm(perm))
                        .reduce((acc, curr) => acc || curr, false)
                } else if (typeof this.permissions === 'string') {
                    return this.hasPerm(this.permissions)
                }
                return false
            }
            return true
        },
        hasErrors() {
            if (!this.$_.isNil(this.errors)) {
                return !this.$_.isEmpty(this.errors)
            }
            return false
        },
        tabItem() {
            return {
                label: this.label,
                icon: this.icon,
                hidden: this.hidden,
                badge: this.badge,
            }
        },
    },
    watch: {
        tabItem: {
            handler(newval) {
                const idx = this.tabs.findIndex(
                    (page) => page.label === this.tabItem.label
                )
                this.$set(this.tabs, idx, newval)
            },
            deep: true,
        },
    },
    created() {
        this.$options.IsTabPage = true

        const exists = this.tabs.find(
            (page) => page.label === this.tabItem.label
        )
        if (!exists) {
            this.tabs.push(this.tabItem)
        }
    },
}
</script>

<style scoped>
.tab-page {
    padding-top: 0;
}
</style>
