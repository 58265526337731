<template>
    <T3TableView
        :title="modelNamePlural"
        :endpoint="endpoint"
        :fields="fields"
        :modelActions="$modelActions"
        :itemActions="$itemActions"
        :exportExcel="exportExcel"
        :filters="filters"
        :staticFilters="staticFilters"
        :hideSearch="hideSearch"
        :inline="inline"
        :rowStyle="rowStyle"
        v-on="$listeners"
    >
        <template slot="filters">
            <slot name="filters"></slot>
        </template>

        <template slot="modals">
            <slot name="modals"></slot>
        </template>

        <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
    </T3TableView>
</template>

<script>
export default {
    name: 'CRUDListPage',
    props: {
        fields: {
            type: Array,
            required: true,
        },
        routePkParam: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            required: true,
        },
        modelNamePlural: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },

        // Actions
        exportExcel: {
            type: String,
            default: undefined,
        },
        modelActions: {
            type: Array,
            default: () => [],
        },
        itemActions: {
            type: Array,
            default: () => [],
        },

        // Filters
        filters: {
            type: Object,
            default: undefined,
        },
        staticFilters: {
            type: Object,
            default: () => ({}),
        },

        // Appearance
        hideSearch: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        rowStyle: {
            type: [Object, Function],
            default: undefined,
        },

        // Buttons
        noCreate: {
            type: Boolean,
            default: false,
        },
        noEdit: {
            type: Boolean,
            default: false,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        $modelActions() {
            if (this.noCreate) {
                return []
            }
            return [
                ...this.modelActions,
                {
                    type: 'link',
                    action: 'add',
                    icon: 'fa-solid fa-plus',
                    tooltip: `Add ${this.modelName}`,
                    role: 'info',
                    route: `${this.cleanedModelName}Create`,
                },
            ]
        },
        $itemActions() {
            let result = []
            if (this.showDetails) {
                result.push({
                    type: 'link',
                    icon: 'fas fa-eye',
                    tooltip: `Show ${this.modelName} Details`,
                    role: 'info',
                    route: this.detailsRoute,
                })
            }
            if (!this.noEdit) {
                result.push({
                    type: 'link',
                    icon: 'fas fa-edit',
                    tooltip: `Edit ${this.modelName}`,
                    role: 'info',
                    route: this.updateRoute,
                })
            }
            return this.itemActions.concat(result)
        },
        cleanedModelName() {
            return this.modelName.replace(/ /g, '')
        },
    },
    methods: {
        detailsRoute({ pk }) {
            const name = `${this.cleanedModelName}Details`
            const params = {}
            params[this.routePkParam] = pk

            return { name, params }
        },
        updateRoute({ pk }) {
            const name = `${this.cleanedModelName}Update`
            const params = {}
            params[this.routePkParam] = pk

            return { name, params }
        },
    },
}
</script>
