<template>
    <T3FormSection
        :title="title"
        :bordered="bordered"
    >
        <div class="columns">
            <div class="column is-8">
                <T3TextField
                    label="Fiscal Name"
                    v-model="form.fiscal_name"
                    :errors="errors.fiscal_name"
                />
            </div>
            <div class="column is-4">
                <T3TextField
                    label="Fiscal Number"
                    v-model="form.fiscal_number"
                    :errors="errors.fiscal_number"
                />
            </div>
        </div>

        <slot></slot>
    </T3FormSection>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Fiscal Info',
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>
