<template>
    <div class="bordered">
        <h1 class="title is-6">{{ title }}</h1>

        <LeadItem
            v-for="lead in availableLeads"
            :key="lead.pk"
            :lead="lead"
        >
            <template
                v-for="(_, slot) of $scopedSlots"
                v-slot:[slot]="scope"
            >
                <slot
                    :name="slot"
                    v-bind="scope"
                />
            </template>
        </LeadItem>
    </div>
</template>

<script>
import LeadItem from './LeadItem.vue'

export default {
    components: {
        LeadItem,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        leads: {
            type: Array,
            required: true,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        availableLeads() {
            return this.leads.filter((x) => !this.exclude.includes(x.pk))
        },
    },
}
</script>

<style scoped>
.bordered {
    border: solid 1px #ea621f;
    padding: 0.5em 1em;
    margin-top: 1em;
    height: 530px;
    max-height: 530px;
    overflow-y: auto;
}
</style>
