<script>
export default {
    functional: true,
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        item: {
            type: Object,
            default: undefined,
        },
        eventHandler: {
            type: Function,
            required: true,
        },
    },
    render(h, ctx) {
        let buttons = ctx.props.actions
            .filter(
                (action) =>
                    action.show === undefined || action.show(ctx.props.item)
            )
            .map((action) => {
                let iconClass = action.icon
                if (typeof iconClass === 'function') {
                    iconClass = iconClass(ctx.props.item)
                }
                const icon = h('span', { class: 'icon is-small' }, [
                    h('i', { class: iconClass }, []),
                ])

                const classes = ['button', 'action', 'is-small', 'ml-0', 'mr-1']
                if (action.role !== undefined) {
                    classes.push(`is-${action.role}`)
                }

                let extraClasses = action.extraClasses
                if (extraClasses !== undefined) {
                    if (typeof extraClasses === 'function') {
                        classes.push(extraClasses(ctx.props.item))
                    } else {
                        classes.push(extraClasses)
                    }
                }

                let title = action.tooltip
                if (title !== undefined && typeof title === 'function') {
                    title = title(ctx.props.item)
                }

                const buttonAttrs = {
                    attrs: { title },
                    class: classes,
                }

                let tag = 'button'

                if (action.type === 'link') {
                    tag = 'router-link'

                    if (typeof action.route === 'function') {
                        buttonAttrs.props = {
                            to: action.route(ctx.props.item),
                        }
                    } else if (typeof action.route === 'string') {
                        buttonAttrs.props = {
                            to: {
                                name: action.route,
                            },
                        }
                    } else {
                        buttonAttrs.props = {
                            to: action.route,
                        }
                    }
                } else if (action.type === 'download') {
                    let document = action.document
                    let filename = action.filename

                    if (typeof filename === 'function') {
                        filename = filename(ctx.props.item)
                    }

                    if (document) {
                        if (typeof document === 'function') {
                            document = document(ctx.props.item)
                        }

                        buttonAttrs.on = {
                            click: () => {
                                if (filename) {
                                    ctx.parent.$request(
                                        `/t3/documents/${document}/download`,
                                        undefined,
                                        filename,
                                        true
                                    )
                                } else {
                                    ctx.parent
                                        .$request(
                                            `/t3/documents/${document}/details`
                                        )
                                        .then(({ filename }) => {
                                            ctx.parent.$request(
                                                `/t3/documents/${document}/download`,
                                                undefined,
                                                filename,
                                                true
                                            )
                                        })
                                }
                            },
                        }
                    } else {
                        let route = action.route

                        if (typeof route === 'function') {
                            try {
                                route = route(ctx.props.item)
                            } catch (error) {
                                return undefined
                            }
                        } else if (typeof route !== 'string') {
                            throw 'Missing or invalid route argument'
                        }

                        if (typeof filename !== 'string') {
                            throw 'Missing or invalid filename argument'
                        }

                        buttonAttrs.on = {
                            click: () => {
                                ctx.parent.$request(route, undefined, filename)
                            },
                        }
                    }
                } else if (action.type === 'export') {
                    buttonAttrs.on = {
                        click: () =>
                            ctx.props.eventHandler('export', {
                                action: action.action,
                                filename: action.filename,
                            }),
                    }
                } else if (action.type === 'internal') {
                    if (action.handler !== undefined) {
                        buttonAttrs.on = {
                            click: () => action.handler(ctx.props.item),
                        }
                    }
                } else {
                    buttonAttrs.on = {
                        click: () =>
                            ctx.props.eventHandler(
                                action.action,
                                ctx.props.item
                            ),
                    }
                }

                return h(tag, buttonAttrs, [icon])
            })

        return h(
            'div',
            {
                class: 'buttons',
            },
            buttons
        )
    },
}
</script>

<style scoped>
.buttons {
    justify-content: flex-end !important;
}
</style>
