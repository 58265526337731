import isNil from 'lodash.isnil'

import { parseDate, parseDatetime, isTime } from '@/helpers/dates'
import { formatCurrency } from '@/helpers/currency'
import { percent } from '@/helpers/filters'

export default {
    string: {
        value: (val) => val,
        format: (val) => val,
    },
    stringlist: {
        value: (val) => val,
        format: (val) => (val ? val.join(', ') : ''),
    },
    stringlines: {
        value: (val) => val,
        format: (val) => (val ? val.join('<br>') : ''),
    },
    int: {
        value: (val) => parseInt(val),
        format: (val) => val,
    },
    float0: {
        value: (val) => parseFloat(val),
        format: (val) => parseFloat(val)?.toFixed(0) || null,
    },
    float1: {
        value: (val) => parseFloat(val),
        format: (val) => parseFloat(val)?.toFixed(1) || null,
    },
    float2: {
        value: (val) => parseFloat(val),
        format: (val) => parseFloat(val)?.toFixed(2) || null,
    },
    coords: {
        value: (val) => parseFloat(val),
        format: (val) => parseFloat(val)?.toFixed(6) || null,
    },
    km: {
        value: (val) => parseFloat(val),
        format: (val) => `${parseFloat(val)?.toFixed(2)} km` || null,
    },
    currency: {
        value: (val) => parseFloat(val),
        format: (val, item, field) =>
            formatCurrency(val, field?.currency || item?.currency || 'EUR'),
    },
    percent: {
        value: (val) => parseFloat(val),
        format: (val) => percent(val),
    },
    date: {
        value: (val) => parseDate(val),
        format: (val) => val,
    },
    time: {
        value: (val) =>
            isTime(val) ? val.split(':').map((x) => parseInt(x)) : [0, 0],
        format: (val) => val,
    },
    datetime: {
        value: (val) => parseDatetime(val),
        format: (val) => val,
    },
    check: {
        value: (val) => val,
        format: (val) => (val ? '✔' : ''),
    },
    link: {
        value: (val) => {
            if (!isNil(val) && typeof val === 'object') {
                return val.label
            }
            return val
        },
        format: (val) => {
            if (!isNil(val) && typeof val === 'object') {
                return val.label
            }
            return val
        },
    },
    choices: {
        value: (val) => val,
        format: (val) => val,
    },
    rating: {
        value: (val) => val,
        format: (val) => {
            switch (val) {
                case 0:
                    return ''
                case 1:
                    return '★'
                case 2:
                    return '★★'
                case 3:
                    return '★★★'
                case 4:
                    return '★★★★'
                case 5:
                    return '★★★★★'
                default:
                    return ''
            }
        },
    },
}
