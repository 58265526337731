<template>
    <T3ChoicesField
        label="Lang"
        :choices="choices"
        v-model="lang"
    />
</template>

<script>
import { defaultLangs, langName } from '@/helpers/i18n'

export default {
    name: 'T3LangComboField',
    props: {
        value: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        choices() {
            return defaultLangs().map((lang) => [lang, langName(lang)])
        },
        lang: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
    },
}
</script>
