<template>
    <div>
        <div class="tabs is-centered is-boxed is-small">
            <ul>
                <li
                    v-for="(tab, index) in visibleTabs"
                    :key="index"
                    :class="{
                        'is-active': tab.label === active,
                        'has-errors': tab.hasErrors,
                    }"
                >
                    <a @click="onTabLinkClicked(tab)">
                        <span
                            class="icon is-small"
                            v-if="tab.icon"
                        >
                            <i :class="tab.icon"></i>
                        </span>
                        <span
                            v-if="tab.badge"
                            class="badge has-badge-rounded is-badge-small"
                            :data-badge="tab.badge"
                        >
                            {{ tab.label }}
                        </span>
                        <template v-else>{{ tab.label }}</template>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-details">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'

export default {
    data: () => ({
        tabs: [],
        active: '',
    }),
    provide() {
        return {
            tabs: computed(() => this.tabs),
            active: computed(() => this.active),
        }
    },
    computed: {
        visibleTabs() {
            return this.tabs.filter((page) => !page.hidden)
        },
    },
    methods: {
        onTabLinkClicked(tab) {
            window.history.replaceState(null, null, `?tab=${tab.label}`)
            this.openTab(tab.label)
        },
        openTab(label) {
            if (label !== this.active) {
                this.active = label
            }
        },
    },
    mounted() {
        if (this.tabs.length > 0) {
            let tab = this.$route.query.tab
            if (tab) {
                for (const tabPage of this.tabs) {
                    if (tabPage.label === tab) {
                        this.openTab(tab)
                        break
                    }
                }
            } else {
                this.active = this.tabs[0].label
            }
        }
    },
}
</script>

<style scoped>
.has-errors > a,
.has-errors > a > span {
    color: #d9534f !important;
}

.nav-tabs > li > a {
    border-color: #ffffff;
    color: rgba(230, 98, 45, 0.5);
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #e6622d;
    border: 1px solid #e6622d;
}

.nav-tabs > li > a:hover {
    color: rgba(230, 98, 45, 0.8);
    cursor: pointer;
}

.active {
    font-weight: bold;
}
</style>
