<template>
    <a
        class="button is-small ml-1 mr-1"
        :class="buttonClasses"
        :disabled="disabled"
        :data-tooltip="tooltip"
        :style="{ backgroundColor }"
        @click="onClicked"
    >
        <span
            v-if="icon"
            class="icon is-small"
        >
            <i :class="icon"></i>
        </span>

        <span v-if="label">{{ label }}</span>
    </a>
</template>

<script>
export default {
    name: 'T3Button',
    props: {
        label: String,
        icon: String,
        color: {
            type: String,
            default: undefined,
        },
        backgroundColor: {
            type: String,
            default: undefined,
        },
        role: {
            type: String,
            default: undefined,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: undefined,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses() {
            let classes = []
            if (this.color) {
                classes.push(`is-${this.color}`)
            }
            if (this.role) {
                classes.push(`is-${this.role}`)
            }
            if (this.outlined) {
                classes.push('is-outlined')
            }
            if (this.expanded) {
                classes.push('expanded')
            }
            if (!this.label) {
                classes.push('textless')
            }
            return classes
        },
    },
    methods: {
        onClicked() {
            if (!this.disabled) {
                this.$emit('clicked')
            }
        },
    },
}
</script>

<style scoped>
.expanded {
    width: 100%;
}
</style>
