<template>
    <canvas ref="canvas"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    props: {
        label: {
            type: String,
            default: undefined,
        },
        value: {
            type: Array,
            required: true,
        },
        borderWidth: {
            type: Number,
            default: 1,
        },
        yLabel: {
            type: String,
            default: undefined,
        },
        ySuffix: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        chart: null,
    }),
    computed: {
        canvasID() {
            return `barplot_${this._uid}`
        },
        labels() {
            return this.value.map((x) => x.label)
        },
        datasets() {
            return [
                {
                    label: this.label,
                    data: this.value.map((x) => x.value),
                    borderWidth: this.borderWidth,
                    backgroundColor: this.value.map((x) => x.backgroundColor),
                },
            ]
        },
    },
    watch: {
        datasets() {
            this.chart.data.labels = this.labels
            this.chart.data.datasets = this.datasets
            this.chart.update()
        },
    },
    mounted() {
        const ctx = this.$refs.canvas

        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: this.label !== undefined,
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: this.yLabel !== undefined,
                            text: this.yLabel,
                        },
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => `${value}${this.ySuffix}`,
                        },
                    },
                },
            },
        })
    },
}
</script>
