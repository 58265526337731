<template>
    <T3Page :title="title">
        <template slot="actions">
            <T3Button
                label="Save and Continue"
                role="primary"
                icon="fas fa-save"
                @clicked="onSaveAndContinueClicked"
            />

            <T3Button
                label="Save"
                role="primary"
                icon="fas fa-save"
                @clicked="onSaveClicked"
            />
        </template>

        <div class="columns">
            <div class="column">
                <T3TextField
                    label="Name"
                    v-model="form.name"
                    :errors="errors.name"
                />
            </div>
            <div class="column is-narrow">
                <br />
                <T3CheckField
                    label="Approved"
                    v-model="form.approved"
                />
            </div>
        </div>

        <slot name="main-form"></slot>

        <div class="columns">
            <div class="column">
                <LeadPanel
                    title="Selected Leads"
                    :leads="selectedLeads"
                >
                    <template v-slot:lead-actions="lead">
                        <T3Button
                            icon="fas fa-minus"
                            role="info"
                            @clicked="removeSelectedLead(lead)"
                        />
                    </template>
                </LeadPanel>
                <slot name="aggregates"></slot>
            </div>
            <div class="column">
                <slot name="filters"></slot>
                <LeadPanel
                    title="Candidates"
                    :leads="availableLeads"
                    :exclude="excludeAvailableLeads"
                >
                    <template v-slot:lead-actions="lead">
                        <T3Button
                            icon="fas fa-plus"
                            role="info"
                            @clicked="addToSelectedLead(lead)"
                        />
                    </template>
                </LeadPanel>
            </div>
        </div>
    </T3Page>
</template>

<script>
import LeadPanel from './LeadPanel.vue'

export default {
    name: 'T3LeadGroup',
    components: {
        LeadPanel,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        selectedLeads: {
            type: Array,
            default: () => [],
        },
        availableLeads: {
            type: Array,
            default: () => [],
        },
        excludeAvailableLeads: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        form: {
            name: '',
            approved: false,
        },
        errors: {},
    }),
    methods: {
        validate() {
            this.errors = {}
            if (this.form.name.trim() === '') {
                this.errors.name = ['Required']
            }
            return this.$_.isEmpty(this.errors)
        },
        initialize(name, approved) {
            this.form.name = name
            this.form.approved = approved
        },
        onSaveClicked() {
            this.$emit('saveRequested', {
                form: this.form,
                goBack: true,
            })
        },
        onSaveAndContinueClicked() {
            this.$emit('saveRequested', {
                form: this.form,
                goBack: false,
            })
        },
        addToSelectedLead({ lead }) {
            this.$emit('addLeadRequested', lead)
        },
        removeSelectedLead({ lead }) {
            this.$emit('removeLeadRequested', lead)
        },
    },
}
</script>
