<template>
    <div
        class="field"
        :class="fieldClasses"
    >
        <label
            class="label is-small"
            v-if="label"
        >
            {{ labelText }}
        </label>

        <div
            class="control"
            :class="controlClasses"
        >
            <input
                ref="input"
                class="input is-small is-fullwidth"
                :class="inputClasses"
                :type="inputType"
                :autocomplete="autocomplete"
                :disabled="readonly"
                v-bind="$attrs"
                v-on="listeners"
            />
            <span
                class="icon is-left is-small"
                v-if="leftIcon"
            >
                <i :class="leftIcon"></i>
            </span>
            <span
                class="icon is-right is-small hide-reveal-icon"
                v-if="password"
                @click.capture="onPasswordHideRevealIconClicked"
            >
                <i
                    class="fas fa-eye-slash"
                    v-if="showPassword"
                ></i>
                <i
                    class="fas fa-eye"
                    v-else
                ></i>
            </span>
            <span
                class="icon is-right is-small"
                v-else-if="rightIcon"
            >
                <i :class="rightIcon"></i>
            </span>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

export default {
    name: 'T3TextField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        password: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: undefined,
        },
    },
    data: () => ({
        showPassword: false,
    }),
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => {
                    let val = event.target.value
                    if (this.maxLength !== undefined) {
                        if (val.length >= this.maxLength) {
                            val = val.substr(0, this.maxLength)
                        }
                    }
                    event.target.value = val
                    this.$emit('input', val)
                },
            }
        },
        inputType() {
            if (this.password && !this.showPassword) {
                return 'password'
            }
            return 'text'
        },
        fieldClasses() {
            if (this.expanded) {
                return 'is-fullwidth'
            }
            return undefined
        },
        controlClasses() {
            let classes = []
            if (this.expanded) {
                classes.push('is-expanded')
            }
            if (this.leftIcon) {
                classes.push('has-icons-left')
            }
            if (this.rightIcon || this.password) {
                classes.push('has-icons-right')
            }
            return classes
        },
        autocomplete() {
            if (this.password) {
                return 'new-password'
            }
            return undefined
        },
    },
    methods: {
        onPasswordHideRevealIconClicked() {
            this.showPassword = !this.showPassword
        },
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>

<style scoped>
.hide-reveal-icon {
    pointer-events: initial !important;
}
.is-fullwidth {
    width: 100% !important;
}
</style>
