<template>
    <div class="columns concept-columns p-2">
        <div :class="conceptClasses">{{ concept }}</div>
        <div :class="descriptionClasses">
            <template v-if="isCurrency">
                {{ $formatCurrency(description, currency) }}
            </template>
            <template v-else-if="isPercent">
                {{ $percent(description) }}
            </template>
            <template v-else>
                {{ description }}
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        concept: {
            type: String,
            required: true,
        },
        conceptIs: {
            type: String,
            default: 'narrow',
        },
        description: {
            type: [String, Number],
            required: true,
        },
        descriptionIs: {
            type: String,
            default: 'narrow',
        },
        isCurrency: {
            type: Boolean,
            default: false,
        },
        isPercent: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: String,
            default: 'EUR',
        },
    },
    computed: {
        conceptClasses() {
            return `column concept is-size-7 is-${this.conceptIs}`
        },
        descriptionClasses() {
            return `column is-size-7 is-${this.descriptionIs}`
        },
    },
}
</script>

<style scoped>
.concept {
    font-weight: bolder !important;
    color: #ea621f !important;
}
</style>
