<template>
    <T3Modal
        :title="title"
        ref="modal"
        v-if="modification && component"
    >
        <component
            :is="component"
            :modification="modification"
        />

        <div
            class="notification is-danger"
            v-if="modification.reason"
        >
            {{ modification.reason }}
        </div>

        <template slot="footer">
            <div class="buttons">
                <T3Button
                    label="Close"
                    @clicked="onCloseClicked"
                />
                <T3Button
                    role="danger"
                    label="Reject"
                    @clicked="onRejectClicked"
                    v-if="modification.status === 0"
                />
                <T3Button
                    role="success"
                    label="Accept"
                    @clicked="onConfirmClicked"
                    v-if="modification.status === 0"
                />
            </div>
        </template>
    </T3Modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Modification Request',
        },
    },
    data: () => ({
        component: null,
        modification: null,
    }),
    methods: {
        open(modification, component) {
            this.modification = modification
            this.component = component
            this.$nextTick(() => {
                this.$refs.modal.show()
            })
        },
        onCloseClicked() {
            this.$refs.modal.hide()
            this.modification = null
            this.component = null
        },
        onRejectClicked() {
            this.$emit('rejected', this.modification)
            this.$refs.modal.hide()
            this.modification = null
            this.component = null
        },
        onConfirmClicked() {
            this.$emit('accepted', this.modification)
            this.$refs.modal.hide()
            this.modification = null
            this.component = null
        },
    },
}
</script>
