export default {
    props: {
        label: String,
        icon: String,
        errors: {
            type: Array,
            default: () => [],
        },
        rightAligned: {
            type: Boolean,
            default: false,
        },
    },
}
