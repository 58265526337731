<template>
    <router-link
        :class="buttonClasses"
        :to="routeValue"
        :data-tooltip="dataTooltip"
        :disabled="disabled"
        :target="target"
    >
        <span
            v-if="icon"
            class="icon is-small"
            :class="{
                'with-label': label ? true : false,
            }"
        >
            <i :class="icon"></i>
        </span>

        <template v-if="label">
            {{ label }}
        </template>
    </router-link>
</template>

<script>
import Control from './Control'

export default {
    name: 'T3ButtonLink',
    extends: Control,
    props: {
        route: {
            type: [Object, String],
            required: true,
        },
        params: {
            type: Object,
            default: undefined,
        },
        query: {
            type: Object,
            default: undefined,
        },
        color: {
            type: String,
            default: undefined,
        },
        role: {
            type: String,
            default: undefined,
        },
        tooltip: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        hovered: {
            type: Boolean,
            default: false,
        },
        focused: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        newPage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses: function () {
            let classes = ['button', 'is-small']
            if (!this.$_.isNil(this.color)) {
                classes.push(`is-${this.color}`)
            }
            if (!this.$_.isNil(this.role)) {
                classes.push(`is-${this.role}`)
            }
            if (this.outlined) {
                classes.push('is-outlined')
            }
            if (this.inverted) {
                classes.push('is-inverted')
            }
            if (this.rounded) {
                classes.push('is-rounded')
            }
            if (this.hovered) {
                classes.push('is-hovered')
            }
            if (this.focused) {
                classes.push('is-focused')
            }
            if (this.active) {
                classes.push('is-active')
            }
            if (this.loading) {
                classes.push('is-loading')
            }
            return classes.join(' ')
        },
        routeValue() {
            if (typeof this.route === 'string') {
                return {
                    name: this.route,
                    params: this.params,
                    query: this.query,
                }
            }
            return this.route
        },
        dataTooltip() {
            if (this.tooltip !== '') {
                return this.tooltip
            }
            return undefined
        },
        target() {
            if (this.newPage) {
                return '_blank'
            }
            return undefined
        },
    },
}
</script>

<style scoped>
.button {
    margin-left: 2px;
    margin-right: 2px;
}

.with-label {
    margin-right: 3px !important;
}
</style>
