<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label !== undefined"
            >{{ labelText }}</label
        >
        <div class="editor cont">
            <wysiwyg
                ref="editor"
                :options="{ maxHeight }"
                v-model="$value"
                forcePlainTextOnPaste
            />
            <i class="fa-regular fa-face-smile icons" title="Emojis" @mousedown="emojis = ! emojis"></i>
            <div v-if="emojis" class="emojis">
                <div class="columns row" v-for="(row, index) in listEmojis" :key="index">
                    <div class="column emoji" v-for="(column, index2) in row" :key="index2" @click="paste(column)">
                        {{ column }}
                    </div>
                </div>
            </div>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    extends: Field,
    name: 'T3RichTextAreaField',
    props: {
        value: {
            type: String,
            default: '',
        },
        maxHeight: {
            type: String,
            default: '100%',
        },
    },
    data: () => ({
        emojis: false,
        listEmojis: [
            ['😀', '😃', '😄', '😁', '😆'],
            ['😅', '🤣', '😂', '🙂', '🙃'],
            ['🫠', '😉', '😊', '😇', '🥰'],
            ['😍', '🤩', '😘', '😗', '🥱'],
            ['😚', '😙', '🥲', '😋', '😛'],
            ['😜', '🤪', '😝', '🤑', '🤗'],
            ['🤭', '🫢', '🫣', '🤫', '🤔'],
            ['🫡', '🤐', '🤨', '😐', '😑'],
            ['😶', '🫥', '😏', '😒', '🙄'],
            ['😬', '🤥', '🫨', '😌', '😔'],
            ['😪', '🤤', '😴', '😷', '🤒'],
            ['🤕', '🤢', '🤮', '🤧', '🥵'],
            ['🥶', '🥴', '😵', '🤯', '🤠'],
            ['🥳', '🥸', '😎', '🤓', '🧐'],
            ['😕', '🫤', '😟', '🙁', '😮'],
            ['😯', '😲', '😵', '😳', '🥺'],
            ['🥹', '😦', '😧', '😨', '😰'],
            ['😥', '😢', '😭', '😱', '😖'],
            ['😣', '😞', '😓', '😩', '😫'],
            ['😤', '😡', '😠', '🤬', '😈'],
            ['👿', '💀', '💩', '🤡', '👻'],
            ['💋', '💔', '🧡', '💯', '👋'],
        ]
    }),
    computed: {
        $value: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
    },
    methods : {
        paste(emoji) {
            this.$refs.editor.$refs.content.focus()
            document.execCommand("insertHTML", false, emoji)
        }
    },
    watch: {
        maxHeight(newval) {
            this.$refs.editor.$refs.content.style.height = newval
            this.$refs.editor.$refs.content.style.maxHeight = newval
        },
    },
}
</script>

<style scoped>
.field {
    width: 100%;
}
.cont {
    position: relative;
}
.icons{
    position: absolute;
    top: 0;
    right: 5px;
    padding: 9px 6px 7px 6px;
    cursor: pointer;
}
.icons:hover{
    background-color: #DDDDDD;
}
.emojis {
    position: absolute;
    top: 35px;
    right: 19px;
    width: 227px;
    height: 198px;
    background-color: #F6F6F6;
    border: 1px solid #DDDDDD;
    overflow-y: scroll;
}

.row {
    width: 100%;
    margin: 0;
}
.emoji {
    padding: 7px 10px 7px 10px;
    border: 1px solid #DDDDDD;
    margin: 0 0 -13px 0;
    cursor: pointer;
}
.emoji:hover {
    background-color: #DDDDDD;
}
</style>
