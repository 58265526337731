import Vue from 'vue'

import './charts'
import './controls'
import './containers'
import './modals'
import './visualization'

import './new'
import './sections'
import './specific'
import './tabs'
import './utils'
import './views'

import DropdownMenu from './DropdownMenu.vue'
import LangFlag from './LangFlag.vue'

Vue.component('T3DropdownMenu', DropdownMenu)
Vue.component('T3LangFlag', LangFlag)
