<template>
    <fieldset>
        <legend
            class="is-size-7"
            v-if="title !== undefined"
        >
            {{ title }}
        </legend>
        <slot></slot>
    </fieldset>
</template>

<script>
export default {
    props: {
        title: String,
    },
}
</script>

<style scoped>
legend {
    padding: 2px;
    margin-left: 1rem;
    color: #ea621f;
    font-weight: bold;
    text-transform: uppercase;
}
fieldset {
    border: solid 1px #ea621f;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 0.5rem;
    background-color: #f8f8f8;
}
</style>
