<template>
    <div class="columns is-multiline">
        <div
            class="column"
            :class="rowsClass"
            :key="picture.pk"
            v-for="picture in pictures"
        >
            <div class="card">
                <header class="card-image">
                    <img
                        :src="picture.url"
                        :alt="picture.description"
                    />
                </header>
                <footer class="card-footer">
                    <a
                        :href="picture.url"
                        class="card-footer-item info"
                        target="_blank"
                    >
                        View
                    </a>
                    <a
                        href="#"
                        class="card-footer-item info"
                        @click="onEditClicked(picture.pk)"
                    >
                        Edit
                    </a>
                    <a
                        href="#"
                        class="card-footer-item danger"
                        @click="onDeleteClicked(picture.pk)"
                    >
                        Delete
                    </a>
                </footer>
            </div>
        </div>

        <T3ConfirmationModal
            title="Picture Deletion Confirmation"
            ref="confirmationModal"
            @confirmed="onDeleteConfirmed"
        >
            Are you sure that you want to delete this picture?
        </T3ConfirmationModal>
    </div>
</template>

<script>
export default {
    props: {
        endpoint: {
            type: String,
            required: Boolean,
        },
        columns: {
            type: Number,
            default: 4,
        },
        initialValue: Array,
    },
    data() {
        return {
            pictures: [],
        }
    },
    computed: {
        rowsClass() {
            switch (this.columns) {
                case 1:
                    return 'is-12'
                case 2:
                    return 'is-6'
                case 3:
                    return 'is-4'
                case 4:
                    return 'is-3'
                case 6:
                    return 'is-2'
                case 12:
                    return 'is-1'
            }
            return 'is-3'
        },
    },
    methods: {
        fetchItems() {
            this.$request(this.endpoint).then((response) => {
                this.pictures = response.items
            })
        },
        onEditClicked(picture) {
            this.$emit('editRequested', picture)
        },
        onDeleteClicked(picture) {
            this.$refs.confirmationModal.open(picture)
        },
        onDeleteConfirmed(picture) {
            this.$emit('deleteRequested', picture)
        },
    },
    created() {
        if (this.initialValue !== undefined) {
            this.pictures = this.initialValue
        } else {
            this.fetchItems()
        }
    },
}
</script>

<style scoped>
.columns {
    padding-top: 0.5em;
}

.column {
    padding-bottom: 1em;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 2px 16px;
}

.card-image {
    min-height: 200px;
    max-height: 200px;
}

.facility {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.info {
    color: hsl(204, 86%, 53%);
}

.danger {
    color: #d9534f;
}

img {
    border-radius: 5px 5px 0 0;
    object-fit: cover !important;
    min-height: 200px;
    max-height: 200px;
}
</style>
