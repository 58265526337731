<template>
    <T3ModelField
        label="Location"
        endpoint="/locations/locations/autocomplete"
        v-model="location"
        :filters="filters"
        :errors="errors"
    >
        <template
            slot="extra"
            slot-scope="{ item }"
            v-if="!hideCountry"
        >
            <div class="extra">
                <span class="tag is-black">{{ item.country }}</span>
            </div>
        </template>

        <template
            slot="item"
            slot-scope="{ item }"
        >
            <div class="columns item-columns">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column"></div>
                <div
                    class="column is-narrow is-vertical-center"
                    v-if="!hideCountry"
                >
                    <span class="tag is-black">{{ item.country }}</span>
                </div>
            </div>
        </template>
    </T3ModelField>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: null,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Array,
            default: undefined,
        },
        hideCountry: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        location: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
    },
}
</script>

<style scoped>
.extra {
    padding-top: 2px;
    display: inline-block;
    width: 300px;
    text-align: right;
}

.item-columns {
    padding: 0.5rem;
}
</style>
