<template>
    <div
        class="columns"
        v-if="emails && emails.length > 0"
    >
        <div class="column">
            <h1
                class="title is-5"
                v-if="title !== undefined"
            >
                {{ title }}
            </h1>
            <table
                class="table is-hoverable is-narrow is-fullwidth"
                aria-label="Email List"
            >
                <thead>
                    <tr>
                        <th>Destination</th>
                        <th>Subject</th>
                        <th>Category</th>
                        <th>Send At</th>
                        <th>User</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="email in emails"
                        :key="email.pk"
                    >
                        <td>{{ email.to_email }}</td>
                        <td>{{ email.subject }}</td>
                        <td>{{ email.category }}</td>
                        <td>{{ email.send_at }}</td>
                        <td>{{ email.user }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        title: String,
    },
    data: () => ({
        emails: [],
    }),
    methods: {
        async fetch() {
            this.emails = await this.$request(this.endpoint)
            if (this.emails) {
                this.$emit('loadedCount', this.emails.length)
            }
        },
    },
    async mounted() {
        await this.fetch()
    },
}
</script>

<style scoped>
.table {
    font-size: 8pt;
    border-bottom: solid 1px #ea621f;
}
.table th {
    border-top: solid 1px #ea621f;
    border-bottom: solid 1px #ea621f;
}
.table td {
    vertical-align: middle;
}

.table tr {
    cursor: pointer;
}
</style>
