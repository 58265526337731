<template>
    <T3ModelField
        v-model="hotel"
        rightIcon="h-square"
        :label="label"
        :errors="errors"
        :endpoint="endpoint"
        :readonly="readonly"
        addRoute="HotelCreate"
        editRoute="HotelUpdate"
        routeParamName="hotel"
    >
        <template
            slot="item"
            slot-scope="{ item }"
        >
            <div class="columns px-1 py-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column is-narrow is-vertical-center">
                    {{ item.stars }}
                </div>
                <div class="column"></div>
                <div
                    v-if="item.location"
                    class="column is-narrow is-vertical-center"
                >
                    <span class="tag is-black">{{ item.location }}</span>
                </div>
            </div>
        </template>

        <template
            slot="extra"
            slot-scope="{ item }"
        >
            <span class="mr-2">{{ item.stars }}</span>
            <span
                v-if="item.location"
                class="tag is-black mr-3"
            >
                {{ item.location }}
            </span>
        </template>
    </T3ModelField>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Hotel',
        },
        value: {
            type: Object,
            default: null,
        },
        errors: {
            type: Array,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: '/hotels/autocomplete',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hotel: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
    },
}
</script>
