<template>
    <T3Page
        :title="title"
        :inline="inline"
        :hideHeader="hideHeader"
        :hideBackButton="hideBackButton"
    >
        <template slot="actions">
            <slot name="actions"></slot>
        </template>
        <slot></slot>
    </T3Page>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            required: true,
        },
        routePkParam: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideBackButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ID() {
            return this.$route.params[this.routePkParam]
        },
        title() {
            return `${this.modelName} Details`
        },
        detailEndpoint() {
            return `${this.endpoint}/${this.ID}`
        },
    },
    async created() {
        if (this.ID) {
            try {
                const form = await this.$request(this.detailEndpoint)
                this.$emit('update:form', form)
            } catch (error) {
                this.msgError(error.reason)
            }
        }
    },
}
</script>
