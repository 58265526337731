export const replaceAll = function (search, replacement) {
    let target = this
    return target.replace(new RegExp(search, 'g'), replacement)
}

export const format = function () {
    let i = 0,
        args = arguments
    return this.replace(/{}/g, function () {
        return typeof args[i] !== 'undefined' ? args[i++] : ''
    })
}

export const underize = function () {
    return this.replaceAll(' ', '_')
}

export const capitalize = function () {
    return this.replace(/\w\S*/g, (w) =>
        w.replace(/^\w/, (c) => c.toUpperCase())
    )
}

export const snakeToTitle = function (snake) {
    return snake
        .split('_')
        .map((x) => x.substring(0, 1).toUpperCase() + x.substring(1, x.length))
        .join(' ')
}

export const levenstein = function (string) {
    var a = this,
        b = string + '',
        m = [],
        i,
        j,
        min = Math.min

    if (!(a && b)) return (b || a).length

    for (i = 0; i <= b.length; m[i] = [i++]);
    for (j = 0; j <= a.length; m[0][j] = j++);

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            m[i][j] =
                b.charAt(i - 1) === a.charAt(j - 1)
                    ? m[i - 1][j - 1]
                    : (m[i][j] = min(
                          m[i - 1][j - 1] + 1,
                          min(m[i][j - 1] + 1, m[i - 1][j] + 1)
                      ))
        }
    }

    return m[b.length][a.length]
}
