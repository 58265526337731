<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label"
        >
            {{ label }}
        </label>
        <div class="field has-addons">
            <p
                class="control"
                v-for="[pk, label, icon] in availableChoices"
                :key="pk"
            >
                <button
                    class="button is-small"
                    :class="{
                        'is-primary': value === pk,
                        'is-selected': value === pk,
                    }"
                    @click="onButtonClicked(pk)"
                >
                    <span
                        v-if="icon"
                        class="icon is-small"
                    >
                        <i :class="icon"></i>
                    </span>
                    <span>{{ label }}</span>
                </button>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: undefined,
        },
        value: {
            type: [String, Number],
            default: undefined,
        },
        choices: {
            type: [String, Array],
            required: true,
        },
        noEmpty: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        availableChoices() {
            if (Array.isArray(this.choices)) {
                return this.choices
            } else if (typeof this.choices === 'string') {
                return this.choicesList(this.choices)
            }
            return null
        },
    },
    methods: {
        onButtonClicked(pk) {
            if (this.value === pk) {
                if (!this.noEmpty) {
                    this.$emit('input', undefined)
                }
            } else {
                this.$emit('input', pk)
            }
        },
    },
}
</script>
