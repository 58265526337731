<template>
    <div class="field">
        <p class="control has-icons-left">
            <span class="select is-small">
                <select
                    :value="value"
                    v-on="listeners"
                >
                    <option
                        :value="undefined"
                        hidden
                        selected
                    >
                        Sort by
                    </option>

                    <option
                        :key="title"
                        :value="ordering"
                        v-for="[ordering, title] in options"
                    >
                        {{ title }}
                    </option>
                </select>
            </span>

            <span class="icon is-small is-left">
                <i class="fas fa-sort"></i>
            </span>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        ordering: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
        options() {
            let result = []
            for (const [field, title] of this.ordering) {
                result.push([field, `${title} (Inc)`])
                result.push([`-${field}`, `${title} (Dec)`])
            }
            return result
        },
    },
}
</script>
