import Vue from 'vue'

// Boot
console.log(`T3Admin v1.0.0 `)
console.log(`MODE: ${import.meta.env.MODE}`)

import wysiwyg from 'vue-wysiwyg'
Vue.use(wysiwyg)

import pinia from './stores'
import { useAuthStore } from '@/stores/auth'
const authStore = useAuthStore(pinia)
authStore.initAuth()

import router from './router'

import './mixins'
import './globals'

import './helpers/directives'
import './helpers/filters'
import './helpers/formatters'

import './components'

import App from './App.vue'

import { createSentry } from './plugins/sentry'
import { applyStyles } from '@popperjs/core'
createSentry(router)

new Vue({
    el: '#app',
    router,
    pinia,
    render: (h) => h(App),
})
