<template>
    <div
        class="field"
        v-click-outside="closeSelectLangDropdown"
    >
        <label
            class="label is-small"
            v-if="label !== undefined"
            >{{ label }}</label
        >
        <div class="control">
            <div
                class="field has-addons"
                ref="field"
            >
                <div class="control is-expanded">
                    <input
                        class="input is-small"
                        type="text"
                        v-model="value[fieldName]"
                        :placeholder="placeholder"
                    />
                </div>
                <div class="control">
                    <a
                        class="button is-small"
                        @click="openSelectLangDropdown"
                    >
                        <span class="icon">
                            <i :class="langFlag(selectedLang)"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div
            class="box"
            :style="{ display }"
            ref="langDropdown"
        >
            <p
                class="lang-item"
                v-for="lang in availableLangs"
                :key="lang"
                @click="selectLang(lang)"
            >
                <span class="icon">
                    <i :class="langFlag(lang)"></i>
                </span>
                {{ langName(lang) }}
            </p>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import I18NFieldMixin from './I18NFieldMixin'

export default {
    name: 'I18NTextField',
    extends: Field,
    mixins: [I18NFieldMixin],
}
</script>

<style scoped>
.lang-item {
    cursor: pointer;
}

.lang-item:hover {
    color: #ea621f;
}

.box {
    z-index: 10000;
}
</style>
