<template>
    <div class="columns box">
        <div class="column is-10">
            <T3TextField
                placeholder="Description"
                v-model="l_value.description"
            />
        </div>
        <div class="column">
            <T3NumberField
                placeholder="Amount"
                v-model.number="l_value.cost"
                rightIcon="fa fa-euro-sign"
            />
        </div>
        <div class="column is-narrow">
            <T3Button
                role="danger"
                icon="fa fa-times"
                @clicked="on_remove_clicked"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                description: '',
                cost: 0.0,
            }),
        },
    },
    watch: {
        value(newval) {
            this.l_value = newval
        },
        l_value(newval) {
            this.$emit('input', newval)
        },
    },
    methods: {
        on_remove_clicked() {
            this.$emit('removeRequested')
        },
    },
    data() {
        return {
            l_value: {
                description: '',
                cost: 0.0,
            },
        }
    },
    mounted() {
        this.l_value = this.value
    },
}
</script>

<style scoped>
.box {
    margin-bottom: 25px !important;
}
</style>
