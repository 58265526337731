<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label"
        >
            {{ labelText }}
        </label>
        <div class="control">
            <span
                class="icon is-small"
                :class="{ hasErrors }"
                :key="i"
                v-for="i in 5"
                @click="startClicked(i)"
            >
                <i
                    class="fa fa-star"
                    v-if="i <= value"
                ></i>
                <i
                    class="far fa-star"
                    v-else
                ></i>
            </span>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3RatingField',
    extends: Field,
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        startClicked(index) {
            if (!this.readonly) {
                if (this.value === 0) {
                    this.$emit('input', String(index))
                } else {
                    if (index === this.value) {
                        this.$emit('input', 0)
                    } else {
                        this.$emit('input', index)
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
.icon {
    margin-left: 2px;
    margin-right: 2px;
    color: #ea621f;
}

.icon.hasErrors {
    color: #d9534f !important;
}
</style>
