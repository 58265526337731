<template>
        <div class="is-flex is-relative pb-2" @click.prevent="onClick">
            <label class="switch">
                <input type="checkbox" :checked="value">
                <span class="slider round"></span>
            </label>
            <label class="text label is-small" v-if="label">{{ labelText }}</label>
        </div>
</template>

<script>

import Field from "@/components/controls/Field";

export default {
    name: "ToggleSwitch",
    extends: Field,
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClick() {
            this.$emit('input', !this.value)
        },
    },
}
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #ea621f;
}

input:checked + .slider:before {
    transform: translateX(11px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.text{
   position: relative;
    left: 5px;
}
</style>
