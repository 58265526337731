import { MeiliSearch } from 'meilisearch'

export const createAutocompleteClient = function () {
    const client = new MeiliSearch({
        host: import.meta.env.VITE_APP_SEARCH_BACKEND,
        apiKey: import.meta.env.VITE_APP_SEARCH_APIKEY,
    })
    return client
}

export default {
    createAutocompleteClient,
}
