import Vue from 'vue'

import Concept from './Concept.vue'
import FileName from './FileName.vue'
import Modification from './Modification.vue'
import PanelButton from './PanelButton.vue'
import Stars from './Stars.vue'

Vue.component('T3Concept', Concept)
Vue.component('T3FileName', FileName)
Vue.component('T3Modification', Modification)
Vue.component('T3PanelButton', PanelButton)
Vue.component('T3Stars', Stars)
