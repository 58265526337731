<template>
    <div class="file is-small">
        <label class="file-label">
            <input
                class="file-input"
                type="file"
                name="resume"
                @change="onFileChange"
                :disabled="readonly"
            />
            <span class="file-cta">
                <span class="file-icon">
                    <i :class="icon"></i>
                </span>
                <span class="file-label">{{ label }}</span>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'T3UploadButton',
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'Upload',
        },
        icon: {
            type: String,
            default: 'fa-solid fa-upload',
        },
        form: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async onFileChange(event) {
            const file = event.target.files[0]
            const response = await this.$uploadBucketFile(
                this.endpoint,
                file,
                this.form
            )
            this.$emit('uploaded', response.body)
        },
    },
}
</script>
