<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label !== undefined"
            >{{ labelText }}</label
        >
        <vue-tags-input
            v-model="tag"
            :class="{ focused }"
            :tags="tags"
            :autocomplete-items="autocompleteItems"
            :add-only-from-autocomplete="true"
            :placeholder="placeholder"
            @tags-changed="update"
            @focus="focused = true"
            @blur="focused = false"
        >
            <template v-slot:autocomplete-item="{ item, performAdd, selected }">
                <span
                    class="tag"
                    :class="selected ? 'is-primary' : 'is-light'"
                    @click="performAdd(item)"
                >
                    {{ item.text }}
                </span>
            </template>
        </vue-tags-input>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

import VueTagsInput from '@johmun/vue-tags-input'

export default {
    name: 'T3ChoicesTagsField',
    extends: Field,
    components: {
        VueTagsInput,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Add',
        },
        choices: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        tag: '',
        focused: false,
    }),
    computed: {
        tags() {
            if (!this.$_.isNil(this.value)) {
                return this.value.map((pk) => ({
                    pk,
                    text: this.choicesLabel(this.choices, pk),
                    tiClasses: ['ti-valid'],
                }))
            }
            return []
        },
        autocompleteItems() {
            const choices = this.choicesList(this.choices).filter(
                (x) => !(this.values || []).includes(x[0])
            )
            if (choices) {
                return choices.map(([pk, label]) => ({
                    pk,
                    text: label,
                }))
            }
            return []
        },
    },
    methods: {
        update(tags) {
            this.$emit(
                'input',
                tags.map(({ pk }) => pk)
            )
        },
    },
}
</script>

<style scoped>
.tag {
    cursor: pointer;
    margin-left: 0.5em;
}
</style>

<style>
.ti-input {
    border: solid #dbdbdb 1px !important;
    border-radius: 2px !important;
    padding: 2px !important;
}

.focused .ti-input {
    border-color: #ea621f;
    box-shadow: 0 0 0 0.1em rgba(234, 98, 31, 0.9);
}
</style>
