<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label !== undefined"
            >{{ labelText }}</label
        >
        <textarea
            :value="value"
            :placeholder="placeholder"
            :rows="rows"
            :disabled="readonly"
            class="textarea is-small"
            v-bind="$attrs"
            v-on="listeners"
        >
        </textarea>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3TextAreaField',
    extends: Field,
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 4,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>
