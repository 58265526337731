export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mandatory: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        rightAligned: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inputClasses() {
            if (this.errors.length > 0) {
                return 'is-danger'
            }
            return undefined
        },
        labelText() {
            if (this.label !== undefined) {
                if (this.mandatory) {
                    return `${this.label} *`
                }
                return this.label
            }
            return undefined
        },
        hasErrors() {
            return this.errors && this.errors.length > 0
        },
    },
}
