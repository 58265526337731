import Vue from 'vue'

import AddressTab from './AddressTab.vue'
import DocumentsTab from './DocumentsTab.vue'
import RoomTypes from './RoomTypes.vue'
import RedsysForm from './RedsysForm.vue'
import SocialTab from './SocialTab.vue'

Vue.component('T3AddressTab', AddressTab)
Vue.component('T3DocumentsTab', DocumentsTab)
Vue.component('T3RoomTypes', RoomTypes)
Vue.component('T3RedsysForm', RedsysForm)
Vue.component('T3SocialTab', SocialTab)
