<template>
    <form
        name="from"
        action="https://sis.redsys.es/sis/realizarPago"
        method="POST"
        ref="paymentForm"
    >
        <input
            type="hidden"
            name="Ds_SignatureVersion"
            value="HMAC_SHA256_V1"
        />
        <input
            type="hidden"
            name="Ds_MerchantParameters"
            :value="payment.parameters"
        />
        <input
            type="hidden"
            name="Ds_Signature"
            :value="payment.signature"
        />
    </form>
</template>

<script>
export default {
    data: () => ({
        payment: {
            parameters: '',
            signature: '',
        },
    }),
    methods: {
        pay(value) {
            this.payment = value
            this.$nextTick(() => {
                this.$refs.paymentForm.submit()
            })
        },
    },
}
</script>
