<template>
    <div class="box is-fullwidth">
        <p
            class="title is-size-7 has-text-weight-bold mb-2"
            v-if="!hideFrom"
        >
            FROM:
        </p>
        <slot
            name="from-state"
            v-if="!hideFrom"
        ></slot>
        <hr v-if="!hideFrom" />
        <p
            class="title is-size-7 has-text-weight-bold mb-2"
            v-if="!hideFrom"
        >
            TO:
        </p>
        <slot name="to-state"></slot>
    </div>
</template>

<script>
export default {
    props: {
        hideFrom: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
