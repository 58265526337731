import Vue from 'vue'

import './specific'

import Control from './Control.vue'

import Button from './Button.vue'
import ButtonLink from './ButtonLink.vue'
import ToggleButton from './ToggleButton.vue'

import SearchField from './SearchField.vue'
import FileField from './FileField.vue'
import StaticField from './StaticField.vue'
import Calendar from './Calendar.vue'
import GroupBox from './GroupBox.vue'

Vue.component('T3Control', Control)

Vue.component('T3Button', Button)
Vue.component('T3ButtonLink', ButtonLink)
Vue.component('T3ToggleButton', ToggleButton)

Vue.component('T3SearchField', SearchField)
Vue.component('T3FileField', FileField)
Vue.component('T3StaticField', StaticField)
Vue.component('T3Calendar', Calendar)

Vue.component('T3GroupBox', GroupBox)

import TranslatedTextSection from './TranslatedTextSection.vue'

Vue.component('T3TranslatedTextSection', TranslatedTextSection)
