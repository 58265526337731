import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

export const createSentry = function (router) {
    if (
        ['staging', 'topgroup', 'toptown', 'toprock'].includes(
            import.meta.env.MODE
        )
    ) {
        const DSNS = {
            staging:
                'https://13f831f444174de0b2b3ccb97a6ad063@bugs.topgroups.travel/5',
            topgroup:
                'https://a08d8a84b2bd46aab4c1079abb9126dc@bugs.topgroups.travel/4',
            toptown:
                'https://a08d8a84b2bd46aab4c1079abb9126dc@bugs.topgroups.travel/4',
            toprock:
                'https://a08d8a84b2bd46aab4c1079abb9126dc@bugs.topgroups.travel/4',
        }
        const dsn = DSNS[import.meta.env.MODE]
        console.log(`SENTRY: ${dsn.substring(dsn.length - 1)}`)

        Sentry.init({
            Vue,
            dsn,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation:
                        Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: [
                        'admin.topgroupexpress.com',
                        'admin.t3s.es',
                        'admin.topgroupexpress.com',
                        'admin-toprock.topgroupexpress.com',
                        'admin-toptown.topgroupexpress.com',
                        /^\//,
                    ],
                }),
                new Sentry.Replay(),
            ],
        })

        Sentry.setTag('env', import.meta.env.MODE)
    }
}

export default {
    createSentry,
}
