<template>
    <table
        class="table is-bordered is-stripped is-narrow is-fullwidth is-size-7 datatable"
        aria-hidden="true"
    >
        <thead>
            <slot name="dt-header"></slot>

            <tr class="headers">
                <th
                    v-if="checkable && checkPosition === 'left'"
                    class="check-column"
                    scope="col"
                >
                    <T3CheckField v-model="selectingAll" />
                </th>
                <th
                    class="index-column"
                    scope="col"
                    :style="{
                        width: `${meta.indexWidth}rem`,
                        verticalAlign: 'middle',
                    }"
                    v-if="showIndex"
                >
                    #
                </th>

                <ColumnHeader
                    v-for="field in $fields"
                    :key="field.id"
                    :field="field"
                    :align="field.textAlign"
                    :sortable="field.sortable"
                    @sort="onSortClicked"
                />

                <th
                    class="actions-column"
                    :style="{ width: buttonsColumnWidth }"
                    v-if="hasItemButtons"
                >
                    <ActionsButtons
                        :actions="modelActions"
                        :eventHandler="dispatchAction"
                    />
                </th>

                <th
                    class="check-column"
                    scope="col"
                    v-if="checkable && checkPosition === 'right'"
                >
                    <T3CheckField v-model="selectingAll" />
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(item, index) in $items">
                <tr
                    scope="row"
                    :key="index"
                    :class="{
                        'details-row': item.$showDetails,
                        hovered: hoveredRow === index,
                    }"
                    :style="item.$style"
                    @mouseenter="hoveredRow = index"
                    @mouseleave="hoveredRow = null"
                >
                    <td
                        data-label="Checked"
                        v-if="checkable && checkPosition === 'left'"
                    >
                        <T3CheckField v-model="item.$checked" />
                    </td>
                    <td
                        class="number-column"
                        data-label="Index"
                        v-if="showIndex"
                    >
                        {{ item.$index }}
                    </td>

                    <td
                        v-for="field in $fields"
                        :key="field.id"
                        :class="field.class"
                        :style="field.style"
                    >
                        <slot
                            :name="`field-${field.id}`"
                            :item="item"
                            v-if="
                                item[field.id] !== null &&
                                item[field.id] !== undefined
                            "
                        >
                            <router-link
                                v-if="field.type === 'link' && field.route"
                                :to="fieldItemRoute(field, item)"
                                :title="
                                    field.formatter(item[field.id], item, field)
                                "
                            >
                                {{
                                    lengthFilter(
                                        field,
                                        field.formatter(
                                            item[field.id],
                                            item,
                                            field
                                        )
                                    )
                                }}
                            </router-link>
                            <template v-else-if="field.type === 'choices'">
                                {{
                                    lengthFilter(
                                        field,
                                        choicesLabel(
                                            field.choices,
                                            item[field.id]
                                        )
                                    )
                                }}
                            </template>
                            <template v-else>
                                <span
                                    v-if="!field.formatter"
                                    :title="item[field.id]"
                                >
                                    {{ lengthFilter(field, item[field.id]) }}
                                </span>
                                <span
                                    v-else
                                    :title="
                                        field.formatter(
                                            item[field.id],
                                            item,
                                            field
                                        )
                                    "
                                    v-html="
                                        lengthFilter(
                                            field,
                                            field.formatter(
                                                item[field.id],
                                                item,
                                                field
                                            )
                                        )
                                    "
                                />
                            </template>
                        </slot>
                    </td>

                    <td
                        class="actions-column"
                        v-if="hasItemButtons"
                        data-label="Actions"
                    >
                        <ActionsButtons
                            :actions="$itemActions"
                            :item="item"
                            :eventHandler="dispatchAction"
                        />
                    </td>

                    <td
                        data-label="Checked"
                        v-if="checkable && checkPosition === 'right'"
                    >
                        <T3CheckField v-model="item.$checked" />
                    </td>
                </tr>

                <tr
                    :key="`d${index}`"
                    :class="{
                        hovered: hoveredRow === index,
                    }"
                    v-if="$scopedSlots['item-details'] && item.$showDetails"
                    @mouseenter="hoveredRow = index"
                    @mouseleave="hoveredRow = null"
                >
                    <td v-if="showIndex"></td>
                    <td :colspan="columnCount - (showIndex ? -1 : 0)">
                        <slot
                            name="item-details"
                            :item="item"
                        >
                        </slot>
                    </td>
                </tr>
            </template>
        </tbody>
        <tfoot>
            <slot name="dt-footer"> </slot>
        </tfoot>
    </table>
</template>

<script>
import { routeParams } from '@/router'

import Table from './Table'

import ActionsButtons from './parts/ActionsButtons.vue'
import ColumnHeader from './parts/ColumnHeader.vue'

import fieldTypes from './types'

export default {
    components: {
        ActionsButtons,
        ColumnHeader,
    },
    extends: Table,
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        currentIndex: {
            type: Number,
            default: 0,
        },
        rowStyle: {
            type: [Object, Function],
            default: undefined,
        },
    },
    data: () => ({
        selectingAll: false,
        meta: {
            indexWidth: 1,
        },
        ordering: null,
        localSorting: null,
        hoveredRow: null,
    }),
    computed: {
        $fields() {
            return this.fields.map((field) => {
                const type = fieldTypes[field.type || 'string']

                const style = {
                    textAlign: field.align,
                    width: field.width,
                }

                let sortable = true
                if (field.sortable === false) {
                    sortable = false
                }

                return {
                    id: field.id,
                    type: field.type,
                    title: field.label,
                    currency: field.currency,
                    formatter: field.formatter || type.format,
                    style: this.$_.isEmpty(style) ? undefined : style,
                    route: field.route,
                    routePk: field.routePk,
                    choices: field.choices,
                    maxLength: field.maxLength,
                    sortable,
                }
            })
        },
        $fieldsMap() {
            const result = {}
            if (this.$fields) {
                this.$fields.forEach((field) => {
                    result[field.id] = field
                })
            }
            return result
        },
        $items() {
            const result = this.$_.clone(this.items)

            if (this.rowStyle) {
                if (typeof this.rowStyle === 'function') {
                    result.forEach((item) => {
                        item.$style = this.rowStyle(item)
                    })
                } else {
                    result.forEach((item) => {
                        item.$style = this.rowStyle
                    })
                }
            }

            if (this.localSorting) {
                const fieldId = this.localSorting.field
                const inc = this.localSorting.inc
                const valueFunction = this.fieldItemValueFunction(fieldId)
                return result.sort((a, b) => {
                    const va = valueFunction(a[fieldId])
                    const vb = valueFunction(b[fieldId])
                    if (va < vb) {
                        return inc ? -1 : 1
                    } else if (va > vb) {
                        return inc ? 1 : -1
                    }
                    return 0
                })
            }

            return result
        },
        $itemActions() {
            const actions = [...this.itemActions]
            if (this.$scopedSlots['item-details']) {
                actions.push({
                    type: 'internal',
                    tooltip: (item) =>
                        item.$showDetails ? 'Hide Details' : 'Show Details',
                    icon: (item) =>
                        item.$showDetails
                            ? 'fas fa-chevron-circle-up'
                            : 'fas fa-angle-down',
                    role: 'info',
                    handler: (item) => {
                        this.$set(
                            item,
                            '$showDetails',
                            item.$showDetails ? false : true
                        )
                        this.$forceUpdate()
                    },
                })
            }
            return actions
        },
        buttonsColumnWidth() {
            let buttonsCount = this.$itemActions.length
            if (this.modelActions.length > buttonsCount) {
                buttonsCount = this.modelActions.length
            }
            return `${buttonsCount * 35 + 2 + buttonsCount - 1}px`
        },
        hasItemButtons() {
            return this.$itemActions.length > 0 || this.modelActions.length > 0
        },
    },
    watch: {
        items(newval) {
            let index = this.currentIndex

            for (const item of newval) {
                this.$set(item, '$checked', item.$checked || this.selectingAll)
                item.$index = ++index
            }
        },
        selectingAll(newval) {
            for (const item of this.items) {
                item.$checked = newval
            }
        },
        currentIndex(newval) {
            this.meta.indexWidth = `${newval}`.length
        },
    },
    methods: {
        lengthFilter(field, value) {
            if (
                field.maxLength &&
                field.maxLength > 3 &&
                value.length > field.maxLength
            ) {
                return value.substring(0, field.maxLength - 3) + '...'
            }
            return value
        },
        fieldItemRoute(field, item) {
            const route = field.route

            if (typeof route === 'string') {
                const args = routeParams(route)
                if (args && args.length > 0) {
                    const params = {}
                    params[args[0]] = this.modelPk(
                        item[field.id],
                        field.routePk || 'pk'
                    )
                    return {
                        name: route,
                        params: params,
                    }
                }
            } else if (typeof route === 'function') {
                return route(item)
            }
            return route
        },
        fieldItemValueFunction(fieldId) {
            const fieldType = this.$fieldsMap[fieldId]?.type || 'string'
            return fieldTypes[fieldType].value
        },
        onSortClicked(sorting) {
            this.$set(this, 'localSorting', sorting)
        },
        dispatchAction(action, item) {
            this.$emit(action, item)
        },

        modelPk(value, pkName = 'pk') {
            if (value) {
                if (typeof value === 'object') {
                    return value[pkName]
                }
                if (typeof value === 'string' || typeof value === 'number') {
                    return value
                }
            }
            return undefined
        },
    },
}
</script>

<style scoped>
.check-column {
    width: 15px;
    padding: 0;
}

.index-column {
    text-align: right !important;
}

.number-column {
    text-align: right;
}

.datatable {
    border: solid 3px #ea621f !important;
    border-collapse: unset;
    margin-top: 1rem;
}

.datatable tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
}

.datatable th {
    font-weight: bolder !important;
    color: #ea621f !important;
    text-transform: uppercase;
    border-bottom: 1px solid #ea621f !important;
}

.datatable .headers th {
    border-top: 1px solid #ea621f !important;
}

.datatable td {
    vertical-align: middle !important;
    border-bottom: solid 1px lightgray !important;
}

.details-row td {
    border-bottom: 0px !important;
}

.actions-column {
    padding-left: 1px !important;
    padding-right: 0px !important;
    text-transform: none !important;
}

.datatable thead {
    border: solid 1px #ea621f;
}

.datatable tfoot {
    border: solid 1px #ea621f;
}

.datatable a:hover {
    text-decoration: none !important;
}

.hovered {
    background-color: rgba(234, 98, 31, 0.15) !important;
}

@media screen and (max-width: 600px) {
    .datatable {
        border: 0 !important;
        width: 98% !important;
        margin-left: 1% !important;
    }

    .datatable thead {
        border: none;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .datatable tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    .datatable td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right !important;
    }

    .datatable td::before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bolder !important;
        color: #ea621f !important;
    }

    .datatable td:last-child {
        border-bottom: 0;
    }
}
</style>
