import Vue from 'vue'

import ClosableMessage from './ClosableMessage.vue'
import CRUDDetailsPage from './CRUDDetailsPage.vue'
import CRUDFormPage from './CRUDFormPage.vue'
import CRUDListPage from './CRUDListPage.vue'
import FormPage from './FormPage.vue'
import FormSection from './FormSection.vue'
import GroupBox from './GroupBox.vue'
import Modal from './Modal.vue'
import Page from './Page.vue'
import TabPage from './TabPage.vue'
import Tabs from './Tabs.vue'

Vue.component('T3ClosableMessage', ClosableMessage)
Vue.component('T3CRUDDetailsPage', CRUDDetailsPage)
Vue.component('T3CRUDFormPage', CRUDFormPage)
Vue.component('T3CRUDListPage', CRUDListPage)
Vue.component('T3FormPage', FormPage)
Vue.component('T3FormSection', FormSection)
Vue.component('T3GroupBox', GroupBox)
Vue.component('T3Modal', Modal)
Vue.component('T3Page', Page)
Vue.component('T3TabPage', TabPage)
Vue.component('T3Tabs', Tabs)
