<template>
    <fieldset>
        <legend v-if="title">{{ title }}</legend>
        <slot></slot>
    </fieldset>
</template>

<script>
export default {
    props: {
        title: String,
    },
}
</script>

<style scoped>
legend {
    padding: 2px;
    margin-left: 1rem;
    color: #ea621f;
    font-weight: bold;
    text-transform: uppercase;
}
fieldset {
    border: solid 2px lightgray;
    margin: 10px;
    padding: 10px;
}
</style>
