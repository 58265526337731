<template>
    <div
        class="columns is-multiline"
        v-if="initialized"
    >
        <div
            class="column is-12"
            v-if="errors"
        >
            <p
                class="help is-danger"
                :key="error"
                v-for="error in errors"
            >
                {{ error }}
            </p>
        </div>

        <div
            class="column is-6"
            v-for="room in roomList"
            :key="room.pk"
        >
            <T3NumberField
                :label="room.name"
                :readonly="readonly"
                :decimals="0"
                rightIcon="fa fa-bed"
                v-model.number="value[room.pk]"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        initialized: false,
    }),
    computed: {
        adults() {
            if (this.initialized) {
                return Object.entries(this.value).reduce(
                    (acc, [pk, count]) => acc + count * this.paxes[pk].adults,
                    0
                )
            }
            return 0
        },
        children() {
            if (this.initialized) {
                return Object.entries(this.value).reduce(
                    (acc, [pk, count]) => acc + count * this.paxes[pk].children,
                    0
                )
            }
            return 0
        },
        pax() {
            return this.adults + this.children
        },
    },
    watch: {
        pax() {
            this.$emit('countsChanged', {
                adults: this.adults,
                children: this.children,
            })
        },
    },
    async created() {
        this.roomList = this.choicesList('RoomCategory').map((row) => ({
            pk: row[0],
            name: row[1],
        }))
        this.paxes = Object.fromEntries(
            this.choicesList('RoomCategory').map((row) => [
                row[0],
                [row[2], row[3]],
            ])
        )
        this.initialized = true
    },
}
</script>
