<template>
    <div class="field is-fullwidth">
        <div class="control">
            <T3ModelField
                :label="label"
                :endpoint="endpoint"
                :filters="filters"
                :errors="errors"
                rightButtonIcon="fas fa-plus"
                v-model="selectedItem"
                @itemActivated="onAddRequested"
                @rightButtonClicked="onAddRequested"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    v-slot:[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </T3ModelField>
        </div>

        <div style="overflow-y: scroll; height: 150px">
            <table
                class="table is-hoverable is-bordered is-fullwidth"
                aria-hidden="true"
                v-if="showTable"
            >
                <tbody>
                    <tr
                        v-for="item in value"
                        :key="item.pk"
                    >
                        <td class="is-size-7 label-cell">
                            <slot
                                :item="item"
                                name="selected-item"
                            >
                                {{ item.label }}
                            </slot>
                        </td>
                        <td class="actions-cel">
                            <span
                                class="icon is-small has-text-danger"
                                @click="onItemRemoveClicked(item)"
                            >
                                <i class="fa fa-times"></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3MultipleModelField',
    extends: Field,
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        endpoint: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        selectedItem: null,
    }),
    computed: {
        showTable() {
            return this.value && this.value.length > 0
        },
    },
    methods: {
        onAddRequested() {
            if (this.$isModel(this.selectedItem)) {
                if (
                    this.value.findIndex(
                        (item) => item.pk === this.selectedItem.pk
                    ) === -1
                ) {
                    this.value.push(this.selectedItem)
                    this.selectedItem = null
                }
            }
        },
        onItemRemoveClicked(item) {
            const index = this.value.findIndex((x) => x.pk === item.pk)
            if (index !== -1) {
                this.value.splice(index, 1)
            }
        },
    },
}
</script>

<style scoped>
table {
    border: solid 1px #dbdbdb;
}
td {
    padding-top: 5px;
    padding-bottom: 0 !important;
}
tr {
    vertical-align: middle !important;
}
span {
    cursor: pointer;
}
.label-cell {
    border-right: 0 !important;
}
.actions-cel {
    width: 1em;
    padding: 1px 5px !important;
    border-left: 0 !important;
    vertical-align: middle;
    text-align: right;
}
</style>
