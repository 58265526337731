<template>
    <T3TabPage
        label="Address"
        :errors="pageErrors"
        icon="fas fa-address-card"
    >
        <div class="columns">
            <div class="column">
                <T3TextField
                    label="Line 1"
                    v-model="value.address_line1"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    label="Line 2"
                    v-model="value.address_line2"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3ModelField
                    :label="countryLabel"
                    :errors="errors.address_country"
                    rightIcon="fas fa-flag"
                    endpoint="/locations/countries/autocomplete"
                    v-model="country"
                />
            </div>
            <div class="column">
                <T3ModelField
                    label="Division"
                    :filters="countryFilters"
                    endpoint="/locations/divisions/autocomplete"
                    v-model="division"
                />
            </div>
            <div class="column">
                <T3ModelField
                    rightIcon="fas fa-map-marker"
                    :label="locationLabel"
                    :filters="countryFilters"
                    :itemLabel="
                        (item) =>
                            $isModel(item) ? item.label.split(':')[0] : ''
                    "
                    :errors="errors.address_location"
                    endpoint="/locations/locations/autocomplete"
                    v-model="value.address_location"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column is-one-third">
                <T3TextField
                    label="Zipcode"
                    v-model="value.address_zipcode"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column is-one-third">
                <T3NumberField
                    label="Latitude"
                    v-model.number="value.address_latitude"
                    :decimals="6"
                />
            </div>
            <div class="column is-one-third">
                <T3NumberField
                    label="Longitude"
                    v-model.number="value.address_longitude"
                    :decimals="6"
                />
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    name: 'T3AddressTab',
    props: {
        value: {
            type: Object,
            required: true,
        },
        countryRequired: {
            type: Boolean,
            default: false,
        },
        locationRequired: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        pageErrors() {
            let result = {}
            if (this.errors.address_country) {
                result.address_country = this.errors.address_country
            }
            if (this.errors.address_location) {
                result.address_location = this.errors.address_location
            }
            return result
        },
        location() {
            return this.value.address_location
        },
        country: {
            get() {
                return this.value.address_country
            },
            set(newval) {
                if (!this.$isModel(newval)) {
                    this.$set(this.value, 'address_division', null)
                    this.$set(this.value, 'address_location', null)
                }
                this.$set(this.value, 'address_country', newval)
            },
        },
        division: {
            get() {
                return this.value.address_division
            },
            set(newval) {
                if (!this.$isModel(newval)) {
                    this.$set(this.value, 'address_location', null)
                }
                this.$set(this.value, 'address_division', newval)
            },
        },
        countryFilters() {
            if (this.$isModel(this.country)) {
                return {
                    country: this.country.pk,
                }
            }
            return null
        },
        countryLabel() {
            if (this.countryRequired) {
                return 'Country *'
            }
            return 'Country'
        },
        locationLabel() {
            if (this.locationRequired) {
                return 'Location *'
            }
            return 'Location'
        },
    },
}
</script>
