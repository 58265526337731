<template>
    <div
        class="field"
        :class="extraFieldClasses"
    >
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ labelText }}
        </label>

        <div
            class="control"
            :class="iconClasses"
        >
            <input
                class="input is-small number-field-input"
                :class="inputClasses"
                v-bind="$attrs"
                v-on="listeners"
                :disabled="readonly"
            />
            <span
                class="icon is-left is-small"
                v-if="leftIcon !== undefined"
            >
                <i :class="leftIcon"></i>
            </span>
            <span
                class="icon is-right is-small"
                v-if="rightIcon !== undefined"
            >
                <i :class="rightIcon"></i>
            </span>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

export default {
    name: 'T3NumberField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        decimals: {
            type: Number,
            default: 2,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minValue: Number,
        maxValue: Number,
        extraFieldClasses: String,
    },
    computed: {
        listeners() {
            const self = this
            return {
                ...this.$listeners,
                input: (event) => {
                    const cleaned = self.cleaned(event.target.value)
                    const value = self.fValue(cleaned)

                    if (value === null) {
                        self.$emit('input', null)
                    } else if (self.isValid(value)) {
                        if (cleaned !== '-') {
                            if (cleaned !== null) {
                                self.$emit(
                                    'input',
                                    value.toFixed(self.decimals)
                                )
                            }
                        }
                    }
                    event.target.value = cleaned
                },
                blur: (event) => {
                    const cleaned = self.cleaned(event.target.value)
                    const value = self.fValue(cleaned)

                    let formatted = null
                    if (value !== null && self.isValid(value)) {
                        formatted = value.toFixed(self.decimals)
                    }

                    if (formatted) {
                        event.target.value = formatted
                    } else {
                        if (this.nullable) {
                            event.target.value = ''
                        } else {
                            const zero = 0
                            formatted = zero.toFixed(this.decimals)
                            event.target.value = formatted
                        }
                    }

                    self.$emit('input', formatted)
                },
            }
        },
    },
    methods: {
        cleaned(value) {
            let replaced = value.replace(',', '.')
            let output = []
            let dot = false
            let decimals = 0
            for (let i = 0; i < replaced.length; i++) {
                const char = replaced.charAt(i)
                if (char === '-' && i === 0) {
                    output.push(char)
                } else if (char === '.' && this.decimals > 0 && !dot) {
                    if (i === 0) {
                        output.push('0.')
                    } else {
                        output.push(char)
                    }
                    dot = true
                } else if (char >= '0' && char <= '9') {
                    if (dot) {
                        decimals += 1
                    }
                    if (decimals > this.decimals) {
                        break
                    }
                    output.push(char)
                }
            }
            return output.join('')
        },
        fValue(value) {
            if (value.charAt(value.length - 1) === '.') {
                value = value.slice(0, value.length - 1)
            }

            if (this.nullable) {
                if (value === '' || value === '-') {
                    return null
                }
                return parseFloat(value)
            }

            if (value === '') {
                return null
            }
            if (value === '-') {
                return 0
            }
            return parseFloat(value)
        },
        isValid(value) {
            if (value) {
                if (this.minValue !== undefined && value < this.minValue) {
                    return false
                }

                if (this.maxValue !== undefined && value > this.maxValue) {
                    return false
                }
            } else {
                if (!this.nullable) {
                    return false
                }
            }

            return true
        },
    },
}
</script>

<style scoped>
.number-field-input {
    text-align: right;
}
</style>
