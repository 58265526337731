<template>
    <T3FormPage
        :title="title"
        @save="onSave"
        @saveAndContinue="onSaveAndContinue"
    >
        <template slot="actions">
            <slot name="actions"></slot>
        </template>
        <slot></slot>
    </T3FormPage>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        routePkParam: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
    },
    computed: {
        ID() {
            return this.$route.params[this.routePkParam]
        },
        title() {
            return this.ID
                ? `Edit ${this.modelName}`
                : `Create ${this.modelName}`
        },
        createEndpoint() {
            return `${this.endpoint}/create`
        },
        detailEndpoint() {
            return `${this.endpoint}/${this.ID}`
        },
        updateEndpoint() {
            return `${this.endpoint}/${this.ID}/edit`
        },
        url() {
            if (this.ID) {
                return this.updateEndpoint
            }
            return this.createEndpoint
        },
        cleanedModelName() {
            return this.modelName.replace(/ /g, '')
        },
    },
    methods: {
        async save() {
            try {
                return await this.$request(this.url, this.form)
            } catch (error) {
                if (error.code === 1000) {
                    this.$emit('update:errors', error.body)
                } else {
                    this.msgError(error.reason)
                }
                return { pk: null }
            }
        },
        async onSave() {
            const { pk } = await this.save()
            if (pk) {
                this.$router.back()
            }
        },
        async onSaveAndContinue() {
            const { pk } = await this.save()
            if (!this.ID) {
                const name = `${this.cleanedModelName}Update`
                const params = {}
                params[this.routePkParam] = pk

                this.$router.replace({ name, params })
            }
        },
    },
    async created() {
        if (this.ID) {
            try {
                const form = await this.$request(this.detailEndpoint)
                this.$emit('update:form', form)
            } catch (error) {
                this.msgError(error.reason)
            }
        }
    },
}
</script>
