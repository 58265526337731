<template>
    <th
        scope="col"
        :style="field.style"
    >
        <a
            class="is-unselectable"
            @click="onClicked"
        >
            {{ field.title }}
            <span
                v-if="sortable"
                class="icon is-small"
            >
                <i :class="sortingIcon"></i>
            </span>
        </a>
    </th>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        align: {
            type: String,
            default: undefined,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        sorting: null,
    }),
    computed: {
        sortingIcon() {
            if (!this.$_.isNil(this.sorting)) {
                if (this.sorting === 'inc') {
                    return 'fas fa-sort-up'
                }
                return 'fas fa-sort-down'
            }
            return 'fas fa-sort'
        },
    },
    methods: {
        onClicked() {
            if (this.$_.isNil(this.sorting) || this.sorting === 'dec') {
                this.sorting = 'inc'
            } else {
                this.sorting = 'dec'
            }
            this.$emit('sort', {
                field: this.field.id,
                inc: this.sorting === 'inc',
            })
        },
    },
}
</script>

<style scoped>
th {
    vertical-align: middle !important;
}
</style>
