<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ label }}
        </label>

        <div
            :class="{
                control: true,
                'is-expanded': expanded,
            }"
        >
            <div
                :class="{
                    select: true,
                    'is-small': true,
                    'is-danger': hasErrors,
                    'is-fullwidth': expanded,
                }"
            >
                <select
                    v-bind="$attrs"
                    :disabled="readonly"
                    v-model="choice"
                >
                    <option
                        v-for="[value, label] in choices"
                        :key="value"
                        :value="value"
                    >
                        {{ label }}
                    </option>
                </select>
            </div>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from '../Field'

export default {
    name: 'T3MonthsChoicesField',
    extends: Field,
    props: {
        value: {
            type: [Number],
            default: null,
        },
        label: {
            type: String,
            default: 'Month',
        },
    },
    computed: {
        choice: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
        choices() {
            return this.choicesList('Month')
        },
    },
}
</script>
