<script>
export default {
    functional: true,
    props: {
        value: {
            type: Number,
            default: 0,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        noEmpty: {
            type: Boolean,
            default: false,
        },
    },
    render(h, ctx) {
        const value = ctx.props.value

        if (value === 0) {
            return h('span', {}, '')
        }

        if (ctx.props.compact) {
            return h('span', {}, `${value}★`)
        }

        let stars = ''

        if (ctx.props.noEmpty) {
            for (let i = 0; i < value; i++) {
                stars += '★'
            }
        } else {
            for (let i = 0; i < 5; i++) {
                if (i < value) {
                    stars += '★'
                } else {
                    stars += '☆'
                }
            }
        }

        return h('span', {}, stars)
    },
}
</script>
