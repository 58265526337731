<template>
    <T3ModelField
        v-model="staff"
        rightIcon="h-square"
        :label="label"
        :errors="errors"
        :endpoint="endpoint"
        :readonly="readonly"
        editRoute="UserUpdate"
        routeParamName="user"
    >
        <template
            slot="item"
            slot-scope="{ item }"
        >
            <div class="columns px-1 py-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column"></div>
                <div class="column is-narrow is-vertical-center">
                    <span class="tag is-info">
                        {{ choicesLabel('Department', item.department) }}
                    </span>
                </div>
            </div>
        </template>

        <template
            slot="extra"
            slot-scope="{ item }"
        >
            <span class="tag is-info mr-4 mt-1">
                {{ choicesLabel('Department', item.department) }}
            </span>
        </template>
    </T3ModelField>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Staff',
        },
        value: {
            type: Object,
            default: null,
        },
        errors: {
            type: Array,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: '/hr/employees/autocomplete',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        staff: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
        },
    },
}
</script>
