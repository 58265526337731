<template>
    <div>
        <div class="columns is-multiline box">
            <div class="column is-4 has-text-weight-bold is-size-7">
                {{ l_value.name }}
            </div>
            <div class="column is-4 has-text-right is-size-7">Hotel Value</div>
            <div class="column is-4 has-text-right is-size-7">Agency Value</div>

            <div
                class="column"
                v-if="show_count"
            >
                <T3NumberField
                    :decimals="0"
                    v-model.number="l_value.count"
                />
            </div>
            <div
                class="column"
                v-if="show_hotel_value"
            >
                <T3NumberField
                    rightIcon="fa fa-euro-sign"
                    v-model.number="l_value.hotel_value"
                />
            </div>
            <div
                class="column"
                v-if="show_agency_value"
            >
                <T3NumberField
                    rightIcon="fa fa-euro-sign"
                    v-model.number="l_value.agency_value"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                pk: 0,
                name: '',
                count: 0,
                hotel_value: 0.0,
                agency_value: 0.0,
            }),
        },
        show_count: {
            type: Boolean,
            default: true,
        },
        show_hotel_value: {
            type: Boolean,
            default: true,
        },
        show_agency_value: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        value(newval) {
            this.l_value = newval
        },
        l_value(newval) {
            this.$emit('input', newval)
        },
    },
    data() {
        return {
            l_value: {
                pk: 0,
                name: '',
                count: 0,
                hotel_value: 0.0,
                agency_value: 0.0,
            },
        }
    },
    mounted() {
        this.l_value = this.value
    },
}
</script>

<style scoped>
.box {
    padding: 10px 2px;
    margin: 0;
}
</style>
