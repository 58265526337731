<template>
    <section>
        <div
            v-for="lang in langs"
            :key="lang"
            class="columns"
        >
            <div class="column is-narrow">
                <span class="icon">
                    <i :class="langFlag(lang)"></i>
                </span>
            </div>
            <div class="column">
                <T3TextField
                    :label="langName(lang)"
                    v-model="value[lang]"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { defaultLangs, langName, langFlag } from '@/helpers/i18n'

export default {
    name: 'T3TranslatedTextSection',
    props: {
        value: Object,
    },
    computed: {
        langs: defaultLangs,
    },
    watch: {
        value(newval) {
            if (newval) {
                this.populateMissing(newval)
            }
        },
    },
    methods: {
        langName,
        langFlag,
        populateMissing(value) {
            this.langs.forEach((lang) => {
                if (!value[lang]) {
                    this.$set(value, lang, '')
                }
            })
        },
    },
    created() {
        if (this.value) {
            this.populateMissing(this.value)
        }
    },
}
</script>
