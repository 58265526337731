<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label"
            >{{ labelText }}</label
        >
        <div class="control has-icons-left flatpickr">
            <input
                class="input is-small"
                :class="inputClasses"
                :id="pickerId"
                v-bind="$attrs"
                @keyup.delete="reset"
                readonly
                data-input
            />
            <span class="icon is-left is-small">
                <i class="fas fa-calendar-alt"></i>
            </span>
        </div>
        <p
            class="help is-danger"
            :key="error"
            v-for="error in errors"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3DateField',
    extends: Field,
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        format: {
            type: String,
            default: 'd/m/Y',
        },
        minValue: {
            type: String,
            default: undefined,
        },
        maxValue: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        pickerId() {
            return `datepicker_${this._uid}`
        },
    },
    watch: {
        minValue() {
            this.createPicker()
        },
        maxValue() {
            this.createPicker()
        },
        readonly() {
            this.createPicker()
        },
        value(newval) {
            if (newval) {
                this.lPicker.setDate([
                    this.$parseDate(newval[0]),
                    this.$parseDate(newval[1]),
                ])
            } else {
                this.lPicker.setDate(null)
            }
        },
    },
    methods: {
        createPicker() {
            if (this.lPicker) {
                delete this.lPicker
            }

            this.lPicker = flatpickr(`#${this.pickerId}`, {
                allowInput: false,
                clickOpens: !this.readonly,
                dateFormat: this.format,
                minDate:
                    this.minValue !== undefined
                        ? this.$parseDate(this.minValue)
                        : undefined,
                maxDate:
                    this.maxValue !== undefined
                        ? this.$parseDate(this.maxValue)
                        : undefined,
                locale: {
                    firstDayOfWeek: 1,
                },
                mode: 'range',
                onChange: this.onChange,
            })
            if (this.value) {
                this.lPicker.setDate([
                    this.$parseDate(this.value[0]),
                    this.$parseDate(this.value[1]),
                ])
            }
        },
        reset() {
            this.lPicker.setDate(null)
            this.$emit('input', null)
        },
        onChange(selectedDates) {
            this.$emit('input', [
                this.$dateToString(selectedDates[0]),
                this.$dateToString(selectedDates[1]),
            ])
        },
    },
    mounted() {
        this.createPicker()
    },
}
</script>

<style scoped>
input {
    min-width: 12rem;
    max-width: 12rem;
}
</style>
