<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label !== undefined"
            >{{ labelText }}</label
        >
        <vue-tags-input
            v-model="tag"
            :class="{ focused }"
            :tags="tags"
            :autocomplete-items="autocompleteItems"
            :add-only-from-autocomplete="true"
            :placeholder="placeholder"
            @tags-changed="update"
            @focus="focused = true"
            @blur="focused = false"
        >
            <template v-slot:autocomplete-item="{ item, performAdd, selected }">
                <span
                    class="tag"
                    :class="selected ? 'is-primary' : 'is-light'"
                    @click="performAdd(item)"
                >
                    {{ item.text }}
                </span>
            </template>
        </vue-tags-input>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

import VueTagsInput from '@johmun/vue-tags-input'
import debounce from 'lodash.debounce'

export default {
    name: 'T3TagsField',
    extends: Field,
    components: {
        VueTagsInput,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Add',
        },
        endpoint: {
            type: String,
            default: undefined,
        },
        choices: {
            type: String,
            default: undefined,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        maxResults: {
            type: Number,
            default: 10,
        },
    },
    data: () => ({
        tag: '',
        autocompleteItems: [],
        focused: false,
    }),
    computed: {
        tags() {
            if (!this.$_.isNil(this.value)) {
                if (this.endpoint !== undefined) {
                    return this.value.map(({ pk, label }) => ({
                        pk,
                        text: label,
                        tiClasses: ['ti-valid'],
                    }))
                } else if (this.choices !== undefined) {
                    return this.value.map((pk) => ({
                        pk,
                        text: this.choicesNames[pk],
                        tiClasses: ['ti-valid'],
                    }))
                }
            }
            return []
        },
        availableChoices() {
            if (this.choices !== undefined) {
                return this.choicesList(this.choices).map(([pk, label]) => ({
                    pk,
                    text: label,
                }))
            }
            return undefined
        },
        choicesNames() {
            if (this.choices !== undefined) {
                let result = {}
                this.choicesList(this.choices).forEach(([pk, label]) => {
                    result[pk] = label
                })
                return result
            }
            return undefined
        },
    },
    watch: {
        tag: debounce(async function () {
            if (this.tag.length < 2) {
                return
            }
            if (this.endpoint !== undefined) {
                const items = await this.$request(
                    this.endpoint,
                    {
                        criteria: this.tag,
                        filters: this.filters,
                        max_ocurrences: this.maxResults,
                    },
                    undefined,
                    true
                )
                if (items.length > 0) {
                    this.autocompleteItems = items.map(({ pk, label }) => ({
                        text: label,
                        pk,
                    }))
                }
            } else if (this.choices !== undefined) {
                this.autocompleteItems = this.availableChoices.filter(
                    ({ pk, text }) =>
                        text.toLowerCase().includes(this.tag.toLowerCase()) &&
                        !this.value.includes(pk)
                )
                this.autocompleteItems.sort((a, b) => a.text.levenstein(b.text))
            }
        }, 300),
    },
    methods: {
        update(newTags) {
            this.tag = ''
            this.autocompleteItems = []
            if (this.endpoint !== undefined) {
                this.$emit(
                    'input',
                    newTags.map(({ pk, text }) => ({ pk, label: text }))
                )
            } else if (this.choices !== undefined) {
                this.$emit(
                    'input',
                    newTags.map(({ pk }) => pk)
                )
            }
        },
    },
}
</script>

<style scoped>
.tag {
    cursor: pointer;
    margin-left: 0.5em;
}
</style>

<style>
.ti-input {
    border: solid #dbdbdb 1px !important;
    border-radius: 2px !important;
    padding: 2px !important;
}

.focused .ti-input {
    border-color: #ea621f;
    box-shadow: 0 0 0 0.1em rgba(234, 98, 31, 0.9);
}
</style>
