import Vue from 'vue'

import clone from 'lodash.clone'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

import { percent } from '@/helpers/filters'

import { last } from '@/helpers/arrays'

import { copyStringToClipboard } from '@/helpers/clipboard'

import {
    isDate,
    isTime,
    isDatetime,
    parseDate,
    parseDatetime,
    dateToString,
    nextDay,
    previousDay,
    today,
    tomorrow,
    datePlusDays,
    daysDelta,
    datesDifferenceInDays,
} from '@/helpers/dates'

import { isEmail } from '@/helpers/emails'

import { isModel, isSameModel } from '@/helpers/models'

import { twoZeroPad, parseNumber } from '@/helpers/numbers'

import { randHex } from '@/helpers/random'

import {
    replaceAll,
    format,
    underize,
    snakeToTitle,
    levenstein,
    capitalize,
} from '@/helpers/strings'

import { langName, langFlag } from '@/helpers/i18n'

import { mimetypeExtension, fullFilename } from '@/helpers/mimetypes'

import { currencyIcon, formatCurrency } from '@/helpers/currency'

import { createAutocompleteClient } from '@/plugins/autocomplete'

Vue.prototype.$_ = {
    clone,
    cloneDeep,
    isEmpty,
    isNil,
}

Vue.prototype.$percent = percent

/* Array Functions */

Array.prototype.last = last

/* String Functions */

String.prototype.replaceAll = replaceAll
String.prototype.format = format
String.prototype.underize = underize
String.prototype.levenstein = levenstein
String.prototype.capitalize = capitalize

Vue.prototype.$snakeToTitle = snakeToTitle
Vue.prototype.$twoZeroPad = twoZeroPad

/* Value Testing */

Vue.prototype.$isModel = isModel
Vue.prototype.$isSameModel = isSameModel

Vue.prototype.$isDate = isDate
Vue.prototype.$isTime = isTime
Vue.prototype.$isDatetime = isDatetime

Vue.prototype.$isEmail = isEmail

/* Parsing Functions */

Vue.prototype.$parseNumber = parseNumber

Vue.prototype.$parseDate = parseDate
Vue.prototype.$parseDatetime = parseDatetime

/* Date Functions */

Vue.prototype.$dateToString = dateToString
Vue.prototype.$nextDay = nextDay
Vue.prototype.$previousDay = previousDay
Vue.prototype.$today = today
Vue.prototype.$tomorrow = tomorrow
Vue.prototype.$datePlusDays = datePlusDays
Vue.prototype.$daysDelta = daysDelta
Vue.prototype.$datesDifferenceInDays = datesDifferenceInDays

/* Random Functions */

Vue.prototype.$randHex = randHex

/* Clipboard Functions */

Vue.prototype.$copyStringToClipboard = copyStringToClipboard

/* I18N Functions */

Vue.prototype.$langName = langName
Vue.prototype.$langFlag = langFlag

/* MIME Types */
Vue.prototype.$mimetypeExtension = mimetypeExtension
Vue.prototype.$fullFilename = fullFilename

/* Currency Functions */
Vue.prototype.$currencyIcon = currencyIcon
Vue.prototype.$formatCurrency = formatCurrency

/* Setting Autocomplete */
const autocompleteClient = createAutocompleteClient()
Vue.prototype.$autocomplete = autocompleteClient
