import Vue from 'vue'

import LeadGroup from './LeadGroup/LeadGroup.vue'
import LeadStatus from './LeadStatus.vue'
import Line from './Line.vue'
import ModelMultipleSelect from './ModelMultipleSelect.vue'
import ModificationStatus from './ModificationStatus.vue'
import RequestStatus from './RequestStatus.vue'
import RoomDescription from './RoomDescription.vue'
import StatusMultipleSelect from './StatusMultipleSelect.vue'

Vue.component('T3LeadGroup', LeadGroup)
Vue.component('T3LeadStatus', LeadStatus)
Vue.component('T3Line', Line)
Vue.component('T3ModelMultipleSelect', ModelMultipleSelect)
Vue.component('T3ModificationStatus', ModificationStatus)
Vue.component('T3RequestStatus', RequestStatus)
Vue.component('T3RoomDescription', RoomDescription)
Vue.component('T3StatusMultipleSelect', StatusMultipleSelect)
