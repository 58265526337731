<template>
    <T3AutocompleteField
        :label="label"
        v-model="$location"
        rightIcon="fas fa-flag"
        index="locations_location"
        :errors="errors"
        i18n
    >
        <template
            slot="extra"
            slot-scope="{ item }"
        >
            <div class="extra">
                <span class="tag is-black mt-1">
                    {{ item.country_name }}
                </span>
            </div>
        </template>

        <template
            slot="item"
            slot-scope="{ item }"
        >
            <div class="columns item-columns p-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column"></div>
                <div class="column is-narrow is-vertical-center">
                    <span class="tag is-black">
                        {{ item.country_name }}
                    </span>
                </div>
            </div>
        </template>
    </T3AutocompleteField>
</template>

<script>
    export default {
        name: 'T3LocationAutocomplete',
        props: {
            label: {
                type: String,
                default: 'Location',
            },
            value: {
                type: Object,
                default: null,
            },
            errors: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            $location: {
                get() {
                    return this.value
                },
                set(newval) {
                    this.$emit('input', newval)
                },
            },
        },
    }
</script>
