<template>
    <div class="calendar no-select">
        <div
            v-if="!hideControls"
            class="columns"
        >
            <div class="column"></div>
            <div class="column is-narrow">
                <div class="field has-addons">
                    <div class="control">
                        <a
                            class="button is-primary is-small"
                            @click="goPreviousMonth"
                        >
                            <span class="icon">
                                <i class="fas fa-chevron-left"></i>
                            </span>
                        </a>
                    </div>
                    <div class="control">
                        <div class="select is-small is-primary">
                            <select v-model.number="month">
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <a
                            class="button is-primary is-small"
                            @click="goNextMonth"
                        >
                            <span class="icon">
                                <i class="fas fa-chevron-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="column is-narrow">
                <div class="field has-addons">
                    <div class="control">
                        <a
                            class="button is-primary is-small"
                            @click="goPreviousYear"
                        >
                            <span class="icon">
                                <i class="fas fa-chevron-left"></i>
                            </span>
                        </a>
                    </div>
                    <div class="control">
                        <input
                            v-model.number="year"
                            id="year"
                            type="number"
                            class="input is-primary is-small"
                        />
                    </div>
                    <div class="control">
                        <a
                            @click="goNextYear"
                            class="button is-primary is-small"
                        >
                            <span class="icon">
                                <i class="fas fa-chevron-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="column"></div>
        </div>

        <br />
        <div class="grid">
            <div class="columns">
                <div class="column weekday">Monday</div>
                <div class="column weekday">Tuesday</div>
                <div class="column weekday">Wednesday</div>
                <div class="column weekday">Thursday</div>
                <div class="column weekday">Friday</div>
                <div class="column weekday">Saturday</div>
                <div class="column weekday">Sunday</div>
            </div>

            <div
                v-for="(row, rowIdx) in currentTable"
                :key="rowIdx"
                class="columns"
            >
                <div
                    v-for="day in row"
                    :key="day.day"
                    :style="{ height: cellHeight }"
                    class="column day"
                    @click.shift.exact="range_handler(day.day)"
                    @click.alt.exact="toogle_day(day.day)"
                >
                    <template v-if="day.day">
                        <span>{{ day.day }}</span>
                        <br />
                        <div class="day-content">
                            <slot :item="day.item"> </slot>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Calendar } from 'calendar'

export default {
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        hideControls: {
            type: Boolean,
            default: false,
        },
        cellHeight: {
            type: String,
            default: '9rem !important',
        },
    },
    data: () => ({
        calendar: undefined,
    }),
    computed: {
        month: {
            get() {
                if (this.value) {
                    return this.value.month
                }
                return undefined
            },
            set(newval) {
                this.$emit('monthChangeRequested', {
                    year: this.year,
                    month: newval,
                })
            },
        },
        year: {
            get() {
                if (this.value) {
                    return this.value.year
                }
                return undefined
            },
            set(newval) {
                if (newval >= 1970) {
                    this.$emit('monthChangeRequested', {
                        year: newval,
                        month: this.month,
                    })
                }
            },
        },
        items() {
            if (this.value) {
                return this.value.items
            }
            return undefined
        },
        currentTable() {
            if (this.value) {
                let rows = []
                const mdc = this.calendar.monthDays(this.year, this.month - 1)
                for (let i = 0; i < mdc.length; i++) {
                    let row = []
                    for (let j = 0; j < 7; j++) {
                        let item = undefined
                        if (
                            mdc[i][j] !== 0 &&
                            this.items.hasOwnProperty(mdc[i][j])
                        ) {
                            item = this.items[mdc[i][j]]
                        }
                        row.push({
                            day: mdc[i][j] !== 0 ? mdc[i][j] : null,
                            item: item,
                        })
                    }
                    rows.push(row)
                }
                return rows
            }
            return undefined
        },
    },
    methods: {
        goPreviousMonth() {
            if (this.month > 1) {
                this.$emit('monthChangeRequested', {
                    year: this.year,
                    month: this.month - 1,
                })
            } else if (this.year > 1970) {
                this.$emit('monthChangeRequested', {
                    year: this.year - 1,
                    month: 12,
                })
            }
        },
        goNextMonth() {
            if (this.month < 12) {
                this.$emit('monthChangeRequested', {
                    year: this.year,
                    month: this.month + 1,
                })
            } else {
                this.$emit('monthChangeRequested', {
                    year: this.year + 1,
                    month: 1,
                })
            }
        },
        goPreviousYear() {
            if (this.year > 1970) {
                this.$emit('monthChangeRequested', {
                    year: this.year - 1,
                    month: this.month,
                })
            }
        },
        goNextYear() {
            this.$emit('monthChangeRequested', {
                year: this.year + 1,
                month: this.month,
            })
        },
    },
    created() {
        this.calendar = new Calendar(1)
        const date = new Date()
        this.$emit('monthChangeRequested', {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
        })
    },
}
</script>

<style scoped>
.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.header {
    text-align: center;
}
.day {
    border: solid 1px #ea621f;
    font-size: small;
    font-weight: bold;
}
.day-content {
    width: 100%;
    height: 7rem;
}
.weekday {
    font-size: small;
    font-weight: bold;
    text-align: center;
    color: #ea621f;
    background-color: rgba(234, 98, 31, 0.1) !important;
}
.columns {
    margin: -1px !important;
}
.column {
    margin-right: -1px !important;
}
.grid {
    border: solid 1.5px #ea621f;
}
#year {
    max-width: 45px;
}
</style>
