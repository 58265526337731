import { createPopper } from '@popperjs/core'
import { defaultLangs, langName, langFlag } from '@/helpers/i18n'

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        label: String,
        placeholder: String,
    },
    data: () => ({
        isActive: false,
        selectedLang: 'en',
    }),
    computed: {
        selectedLangName() {
            return langName(this.selectedLang)
        },
        fieldName() {
            return `${this.field}_${this.selectedLang}`
        },
        display() {
            if (this.isActive) {
                return ''
            }
            return 'none'
        },
        availableLangs() {
            return defaultLangs()
        },
    },
    watch: {
        value() {
            this.availableLangs.forEach((lang) => {
                const fieldName = `${this.field}_${lang}`
                if (!this.value.hasOwnProperty(fieldName)) {
                    this.$set(this.value, fieldName, '')
                }
            })
        },
    },
    methods: {
        setupPopper() {
            if (this.popper === undefined) {
                this.popper = createPopper(
                    this.$refs.field,
                    this.$refs.langDropdown,
                    {
                        placement: 'bottom-end',
                    }
                )
            }
        },
        openSelectLangDropdown() {
            this.isActive = !this.isActive
            this.$nextTick(() => {
                this.setupPopper()
            })
        },
        closeSelectLangDropdown() {
            this.isActive = false
        },
        selectLang(lang) {
            if (this.selectedLang !== lang) {
                this.$emit('langChanged', {
                    oldval: this.selectedLang,
                    newval: lang,
                })
            }
            this.selectedLang = lang
            this.isActive = false
        },
        langName,
        langFlag,
    },
    beforeDestroy() {
        if (this.popper !== undefined) {
            this.popper.destroy()
        }
    },
}
