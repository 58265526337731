export default {
    props: {
        leftIcon: String,
        rightIcon: String,
    },
    computed: {
        iconClasses() {
            let classes = []
            if (this.leftIcon) {
                classes.push('has-icons-left')
            }
            if (this.rightIcon) {
                classes.push('has-icons-right')
            }
            return classes
        },
    },
}
