<script>
import { langFlag } from '@/helpers/i18n'

export default {
    functional: true,
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    render(h, ctx) {
        return h('div', { class: 'centered' }, [
            h(
                'span',
                {
                    class: `flag-icon flag-icon-${langFlag(ctx.props.value)}`,
                },
                []
            ),
        ])
    },
}
</script>

<style scoped>
.centered {
    text-align: center;
}
.flag-icon {
    margin: 0 1px;
    zoom: 150%;
}
</style>
