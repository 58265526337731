<template>
    <T3Control
        :label="label"
        :size="size"
        :controlClasses="['is-expanded']"
        :fieldClasses="['has-addons']"
    >
        <input
            class="input is-small"
            :class="{
                'is-danger': errors ? true : false,
            }"
            type="text"
            v-model="$search"
            :placeholder="placeholder"
            @keyup.enter="requestSearch"
            @keyup.esc="clearSearch"
        />
        <div
            class="control"
            slot="extra"
        >
            <a
                :class="buttonClasses"
                :disabled="!isEnabled"
                :style="{ backgroundColor: buttonColor }"
                @click="requestSearch"
            >
                <span :class="buttonIconClasses">
                    <i class="fa fa-search"></i>
                </span>
                Search
            </a>
        </div>
    </T3Control>
</template>

<script>
import Control from './Control'

export default {
    name: 'T3SearchField',
    extends: Control,
    props: {
        value: {
            type: String,
            default: '',
        },
        role: {
            type: String,
            default: 'primary',
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses() {
            let result = ['button', 'is-small']
            if (this.role) {
                result.push(`is-${this.role}`)
            }
            return result
        },
        buttonIconClasses() {
            return ['icon', 'is-small']
        },
        isEnabled() {
            return this.value.trim() ? true : false
        },
    },
    watch: {
        $search: {
            get() {
                return this.value
            },
            set(newval) {
                this.$emit('input', newval)
            },
            buttonColor: {
                type: String,
                default: undefined,
            },
        },
    },
    methods: {
        requestSearch() {
            if (this.isEnabled) {
                this.$emit('searchRequested', this.value)
            }
        },
        clearSearch() {
            this.$emit('input', '')
            this.$emit('searchCleared')
        },
    },
}
</script>
