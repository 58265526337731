<template>
    <T3Page
        :title="title"
        :inline="inline"
        :hideHeader="hideHeader"
        :hideBackButton="hideBackButton"
    >
        <template slot="rejectActions">
            <slot name="rejectActions"></slot>
        </template>

        <template slot="actions">
            <slot name="actions"></slot>
            <T3Button
                v-if="!hideSaveAndContinue"
                label="Save and Continue"
                icon="fas fa-save"
                role="primary"
                :disabled="disableSave"
                @clicked="onSaveAndContinueClicked"
            />
            <T3Button
                v-if="!hideSave"
                label="Save"
                icon="fas fa-save"
                role="primary"
                :disabled="disableSave"
                @clicked="onSaveClicked"
            />
        </template>
        <slot></slot>
    </T3Page>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: undefined,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideBackButton: {
            type: Boolean,
            default: false,
        },
        hideSaveAndContinue: {
            type: Boolean,
            default: false,
        },
        hideSave: {
            type: Boolean,
            default: false,
        },
        disableSave: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onSaveClicked() {
            this.$emit('save')
        },
        onSaveAndContinueClicked() {
            this.$emit('saveAndContinue')
        },
    },
}
</script>
