<template>
    <div class="field">
        <label class="checkbox is-size-7">
            <input
                class="mr-1"
                type="checkbox"
                :checked="checked"
                :disabled="readonly"
                v-bind="$attrs"
                v-on="listeners"
            />
            {{ label }}
        </label>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3CheckField',
    extends: Field,
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.checked),
            }
        },
        checked() {
            if (this.value) {
                return 'checked'
            }
            return undefined
        },
    },
}
</script>
