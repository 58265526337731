<template>
    <div :class="containerClass">
        <div
            class="level"
            v-if="!hideHeader"
        >
            <div
                class="level-left"
                v-if="title"
            >
                <div class="level-item">
                    <div class="buttons">
                        <T3Button
                            label="Back"
                            @clicked="onBackClicked"
                            v-if="!hideBackButton"
                        />
                        <slot name="rejectActions"></slot>
                    </div>
                </div>
            </div>
            <div class="level-item">
                <h4 class="title is-4">{{ title }}</h4>
            </div>
            <div class="level-right">
                <div class="buttons">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'T3Page',
    props: {
        title: {
            type: String,
            default: '',
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideBackButton: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        containerClass() {
            if (this.inline) {
                return 'inline'
            }
            return 'area'
        },
    },
    methods: {
        onBackClicked() {
            this.$router.back()
        },
    },
}
</script>

<style scoped>
.area {
    padding: 4.5rem 1.5rem;
}
.inline {
    padding-top: 0rem;
    padding-bottom: 0rem;
}
</style>
