<template>
    <article class="message is-primary is-small">
        <div class="message-header">
            <slot name="title"></slot>
            <button
                v-if="!hideCloseButton"
                class="delete"
                @click="onCloseClicked"
            ></button>
        </div>
        <div class="message-body">
            <slot></slot>
        </div>

        <T3ConfirmationModal
            v-if="!noConfirmation"
            :title="confirmationTitle"
            ref="confirmationModal"
            @confirmed="onConfirmed"
        >
            <slot name="confirmationBody">{{ confirmationBody }}</slot>
        </T3ConfirmationModal>
    </article>
</template>

<script>
export default {
    props: {
        hideCloseButton: {
            type: Boolean,
            default: false,
        },
        noConfirmation: {
            type: Boolean,
            default: false,
        },
        confirmationTitle: {
            type: String,
            default: 'Close Confirmation',
        },
        confirmationBody: {
            type: String,
            default: 'Are you sure?',
        },
    },
    methods: {
        onCloseClicked() {
            if (this.noConfirmation) {
                this.$emit('close')
            } else {
                this.$refs.confirmationModal.open()
            }
        },
        onConfirmed() {
            this.$emit('close')
        },
    },
}
</script>
