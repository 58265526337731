<template>
    <div class="columns">
        <div class="column">
            <form
                ref="fileform"
                enctype="multipart/form-data"
                novalidate
            >
                <div
                    class="file is-small is-fullwidth has-name"
                    :class="{ 'has-name': has_filename }"
                >
                    <label class="file-label">
                        <input
                            class="file-input"
                            type="file"
                            name="resume"
                            @change="on_file_change"
                        />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fa fa-upload"></i>
                            </span>
                            <span class="file-label">Choose</span>
                        </span>
                        <span
                            class="file-name"
                            v-if="has_filename"
                        >
                            {{ l_filename }}
                        </span>
                    </label>
                </div>
            </form>
        </div>

        <div
            class="column is-narrow"
            v-if="show_categories"
        >
            <T3ChoicesField
                v-model="l_category"
                choices="DocumentCategory"
                :ignore="ignore_categories"
            />
        </div>

        <div
            class="column is-narrow"
            v-if="show_upload_button"
        >
            <T3Button
                label="Upload"
                role="primary"
                :loading="l_uploading"
                :disabled="!can_upload"
                @clicked="on_upload_button_clicked"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filename: {
            type: String,
            default: '',
        },
        show_categories: {
            type: Boolean,
            default: true,
        },
        show_upload_button: {
            type: Boolean,
            default: true,
        },
        document_type: {
            type: String,
            required: true,
        },
        document_type_id: {
            type: [Number, String],
            default: undefined,
        },
        document_upload_endpoint: {
            type: String,
            default: '/t3/documents/upload',
        },
        ignore_categories: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        has_filename() {
            return this.l_filename !== ''
        },
        can_upload() {
            return !this.$_.isNil(this.l_file)
        },
    },
    methods: {
        on_file_change(event) {
            this.l_file = event.target.files[0]
            this.l_filename = this.l_file.name
        },
        on_upload_button_clicked() {
            if (!this.$_.isNil(this.l_file)) {
                const form_data = new FormData()
                form_data.append('document_type', this.document_type)
                form_data.append('document_type_id', this.document_type_id)
                form_data.append('category', this.l_category)
                form_data.append('file', this.l_file, this.l_filename)
                this.l_uploading = true
                this.$request(this.document_upload_endpoint, form_data)
                    .then(() => {
                        const filename_splits = this.l_filename.split('.')
                        this.$emit('documentUploaded', {
                            category: this.choicesLabel(
                                'DocumentCategory',
                                this.l_category
                            ),
                            name: this.l_filename,
                            extension:
                                filename_splits.length > 0
                                    ? filename_splits[
                                          filename_splits.length - 1
                                      ]
                                    : '',
                        })
                        this.clear()
                    })
                    .finally(() => {
                        this.l_uploading = false
                    })
            }
        },
        clear() {
            this.l_file = null
            this.l_filename = this.filename
            this.$refs.fileform.reset()
        },
    },
    data() {
        return {
            l_file: null,
            l_filename: '',
            l_category: '0',
            l_uploading: false,
        }
    },
    mounted() {
        this.l_filename = this.filename
    },
}
</script>

<style scoped>
.file {
    max-width: 350px !important;
}
</style>
