<template>
    <a
        class="button is-small"
        :class="buttonRole"
        @click="onClick"
    >
        <span
            v-if="buttonIcon"
            class="icon is-small"
        >
            <i :class="buttonIcon"></i>
        </span>
        <template v-if="buttonLabel">{{ buttonLabel }}</template>
    </a>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        label: String,
        icon: String,
        role: String,
        uncheckedLabel: String,
        uncheckedIcon: String,
        uncheckedRole: String,
        checkedLabel: String,
        checkedIcon: String,
        checkedRole: String,
    },
    computed: {
        buttonLabel() {
            if (this.label) {
                return this.label
            }
            if (this.value && this.checkedLabel) {
                return this.checkedLabel
            } else if (!this.value && this.uncheckedLabel) {
                return this.uncheckedLabel
            }
            return undefined
        },
        buttonIcon() {
            if (this.icon) {
                return this.icon
            }
            if (this.value && this.checkedIcon) {
                return this.checkedIcon
            } else if (!this.value && this.uncheckedIcon) {
                return this.uncheckedIcon
            }
            return undefined
        },
        buttonRole() {
            if (this.role) {
                return `is-${this.role}`
            }
            if (this.value && this.checkedRole && this.checkedRole) {
                return `is-${this.checkedRole}`
            } else if (!this.value && this.uncheckedRole) {
                return `is-${this.uncheckedRole}`
            }
            return undefined
        },
    },
    methods: {
        onClick() {
            this.$emit('input', !this.value)
        },
    },
}
</script>
