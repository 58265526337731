<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions } from 'pinia'

import { useChoicesStore } from '@/stores/choices'
import { useAppStore } from '@/stores/app'
import { useCompanyStore } from '@/stores/company'
import { useAuthStore } from '@/stores/auth'

export default {
    name: 'App',
    watch: {
        $token(newval) {
            this.setBearerToken(newval)
        },
    },
    methods: {
        ...mapActions(useAppStore, ['initApp', 'setBuild']),
        ...mapActions(useCompanyStore, ['initCompany']),
        ...mapActions(useChoicesStore, ['fetchChoices']),
        ...mapActions(useAuthStore, ['initAuth', 'clearAuth']),
        async checkConnection() {
            const { connected, build } = await this.$ping()
            this.setBuild(build)

            if (!connected) {
                this.clearAuth()
                if (!this.$route.path.endsWith('/login')) {
                    this.$router.replace('/login')
                }
            }
        },
    },
    async mounted() {
        await this.fetchChoices()

        const companyData = await this.$request('/company')
        this.initCompany(companyData)

        this.setBearerToken(this.$token)

        const { connected, build } = await this.$ping()
        this.setBuild(build)

        if (!connected) {
            this.clearAuth()
        }

        this.initApp()

        if (!connected) {
            if (this.$route.meta.noAuth) {
                return
            }
            if (!this.$route.path.endsWith('/login')) {
                this.$router.replace('/login')
            }
        } else {
            setInterval(async () => {
                await this.checkConnection()
            }, 30000)
        }
    },
}
</script>

<style lang="scss">
@import './assets/t3admin';
</style>
