<template>
    <T3TabPage
        label="Stats"
        icon="fa-solid fa-chart-pie"
    >
        <div class="columns">
            <div class="column">
                <T3FormSection
                    title="Production Stats"
                    bordered
                >
                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Created"
                                :description="value.st_leads_created"
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Created Max Percent"
                                :description="
                                    value.st_leads_created_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Confirmed"
                                :description="value.st_confirmed_leads"
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Confirmed Max Percent"
                                :description="
                                    value.st_confirmed_leads_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Confirmed Percent"
                                :description="value.st_confirmed_ratio * 100"
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Cancelled"
                                :description="value.st_cancelled_leads"
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Cancelled Max Percent"
                                :description="
                                    value.st_cancelled_leads_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Discarded"
                                :description="value.st_discarded_leads"
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Discarded Max Percent"
                                :description="
                                    value.st_discarded_leads_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Paid"
                                :description="value.st_paid_leads"
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Paid Max Percent"
                                :description="
                                    value.st_paid_leads_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>
                </T3FormSection>
            </div>
            <div class="column">
                <T3FormSection
                    title="Financial Stats"
                    bordered
                >
                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Invoiced"
                                :description="value.st_invoiced_euros"
                                isCurrency
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Invoiced Max Percent"
                                :description="
                                    value.st_invoiced_euros_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Paid"
                                :description="value.st_paid_euros"
                                isCurrency
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Paid Max Percent"
                                :description="
                                    value.st_paid_euros_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Earnings"
                                :description="value.st_earnings_euros"
                                isCurrency
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Earnings Max Percent"
                                :description="
                                    value.st_earnings_euros_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Cancelled"
                                :description="value.st_cancelled_euros"
                                isCurrency
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Cancelled Max Percent"
                                :description="
                                    value.st_cancelled_euros_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Earnings Lost"
                                :description="value.st_earnings_lost_euros"
                                isCurrency
                            />
                        </div>

                        <div class="column is-4">
                            <T3Concept
                                concept="Earnings Lost Max Percent"
                                :description="
                                    value.st_earnings_lost_euros_max_ratio * 100
                                "
                                isPercent
                            />
                        </div>
                    </div>
                </T3FormSection>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3FormSection
                    title="Scores"
                    bordered
                >
                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Creation"
                                :description="value.st_score_leads"
                            />
                        </div>
                        <div class="column"></div>
                        <div class="column is-4">
                            <T3Concept
                                concept="Total"
                                :description="value.st_score_total"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Confirmation"
                                :description="value.st_score_confirmed"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Cancelled"
                                :description="value.st_score_cancelled"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Discarded"
                                :description="value.st_score_discarded"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <T3Concept
                                concept="Leads Paid"
                                :description="value.st_score_paid"
                            />
                        </div>
                    </div>
                </T3FormSection>
            </div>
            <div class="column"></div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    name: 'StatsTab',
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>
