<template>
    <T3TabPage
        label="Notes"
        icon="fa fa-clipboard"
        :badge="notesCount"
    >
        <div
            class="columns"
            v-if="addEndpoint"
        >
            <div class="column">
                <T3RichTextAreaField v-model="note" />
            </div>
            <div class="column is-narrow pt-2">
                <T3Button
                    role="primary"
                    label="Add Note"
                    @clicked="onAddNoteClicked"
                    :disabled="!note.trim()"
                />
            </div>
        </div>
        <div
            v-for="note in notes"
            :key="note.timestamp"
            class="box"
        >
            <div class="columns">
                <div class="column">
                    <span class="icon is-small">
                        <i class="fas fa-user"></i>
                    </span>
                    <router-link
                        :to="{
                            name: 'UserUpdate',
                            params: {
                                user: note.user.pk,
                            },
                        }"
                    >
                        {{ note.user.label }}
                    </router-link>
                </div>
                <div class="column is-narrow">
                    <span class="icon is-small">
                        <i class="fas fa-clock"></i>
                    </span>
                    {{ note.timestamp }}
                </div>
            </div>

            <div class="columns">
                <div
                    class="column"
                    v-html="note.note"
                ></div>
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        addEndpoint: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        note: '',
    }),
    computed: {
        notes() {
            return this.value?.notes || []
        },
        notesCount() {
            if (this.value?.notes_count) {
                return `${this.value?.notes_count}`
            }
            return undefined
        },
    },
    methods: {
        async onAddNoteClicked() {
            const { notes, notes_count } = await this.$request(
                this.addEndpoint,
                {
                    note: this.note,
                }
            )
            this.$set(this.value, 'notes', notes)
            this.$set(this.value, 'notes_count', notes_count)
            this.note = ''
        },
    },
}
</script>
