<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label"
        >
            {{ labelText }}
        </label>

        <div class="field">
            <div
                class="file is-small has-name is-fullwidth"
                :class="{ 'is-danger': errors.length > 0 }"
            >
                <label class="file-label">
                    <input
                        class="file-input"
                        type="file"
                        name="resume"
                        :accept="accept"
                        :readonly="readonly"
                        :disabled="readonly"
                        @change="onFileChange"
                    />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">{{ buttonLabel }}</span>
                    </span>
                    <span
                        class="file-name"
                        v-if="filename"
                    >
                        {{ filename }}
                    </span>
                    <span
                        class="file-name"
                        v-else-if="placeholder"
                    >
                        {{ placeholder }}
                    </span>
                </label>
            </div>
        </div>

        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3DocumentField',
    extends: Field,
    props: {
        value: {
            type: [String, Object],
            default: null,
        },
        buttonLabel: {
            type: String,
            default: 'Upload',
        },
        accept: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        content: null,
        selected: undefined,
    }),
    computed: {
        filename() {
            return this.content?.name || this.selected
        },
    },
    watch: {
        value(newval) {
            if (newval) {
                if (this.content === null) {
                    this.selected = newval.label
                    this.$emit('input', null)
                }
            } else {
                this.content = null
            }
        },
    },
    methods: {
        async onFileChange(event) {
            this.content = event.target.files[0]
            if (this.content) {
                const document = await this.$upload(this.content)
                this.$emit('input', document)
            } else {
                this.$emit('input', null)
            }
        },
    },
}
</script>
