<template>
    <div class="box">
        <div class="columns">
            <div class="column">
                <slot
                    name="lead-body"
                    :lead="lead"
                >
                    <div class="columns is-marginless">
                        <div class="column is-lead">
                            <router-link
                                class="title is-7"
                                :to="{
                                    name: 'LeadUpdate',
                                    params: {
                                        lead: lead.pk,
                                    },
                                }"
                            >
                                {{ lead.pk }}
                            </router-link>
                        </div>
                        <div class="column is-narrow">
                            <span class="is-size-7">
                                <strong>Client:</strong>
                                {{ lead.client }}
                            </span>
                        </div>
                        <div class="column"></div>
                        <div class="column is-narrow">
                            <span class="is-size-7">
                                <strong>Hotel:</strong>
                                {{ lead.hotel }}
                            </span>
                        </div>
                    </div>

                    <div class="columns is-marginless">
                        <div class="column is-lead">
                            <T3LeadStatus :status="lead.status" />
                        </div>
                        <div class="column">
                            <span class="is-size-7">
                                <strong>Location:</strong>
                                {{ lead.location }}, {{ lead.country }}
                            </span>
                        </div>
                        <div class="column"></div>
                        <div class="column is-narrow">
                            <span class="is-size-7">
                                <strong>From </strong>
                                {{ lead.check_in }}
                                <strong>To </strong>
                                {{ lead.check_out }}
                                <span class="tag is-black"
                                    >{{ lead.nights }} Nights</span
                                >
                            </span>
                        </div>
                    </div>

                    <div class="columns is-marginless">
                        <div class="column is-lead"></div>
                        <div class="column">
                            <span class="is-size-7">
                                <strong>Price:</strong>
                                {{ $formatCurrency(lead.price) }}
                            </span>
                        </div>
                        <div class="column"></div>
                        <div class="column is-narrow">
                            <span class="is-size-7">
                                <strong>Cost:</strong>
                                {{ $formatCurrency(lead.cost) }}
                            </span>
                        </div>
                    </div>
                </slot>
            </div>
            <div class="column is-narrow">
                <slot
                    name="lead-actions"
                    :lead="lead"
                >
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lead: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>
.is-lead {
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    text-align: right;
}
</style>
