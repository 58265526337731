<template>
    <div class="field">
        <label
            class="label is-small"
            v-if="label"
            >{{ labelText }}</label
        >

        <div
            class="field"
            :class="{ 'has-addons': hasAddons }"
            v-click-outside="onClickOutside"
        >
            <div
                class="control is-expanded"
                :class="iconClasses"
            >
                <input
                    autocomplete="off"
                    v-model="text"
                    class="input is-small is-fullwidth"
                    :class="modelInputClasses"
                    :style="{ 'text-align': textAlign }"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :disabled="readonly"
                    @focus="active = true"
                    @blur="onBlur"
                    @keydown.up="onKeyUpPressed"
                    @keydown.down="onKeyDownPressed"
                    @keyup.enter="onKeyEnterPressed"
                    @keyup.esc="onKeyEscPressed"
                />
                <div class="extra">
                    <slot
                        class="extra"
                        v-if="value"
                        :item="value"
                        name="extra"
                    ></slot>
                </div>
                <span
                    class="icon is-left is-small"
                    v-if="leftIcon !== undefined"
                >
                    <i :class="leftIcon"></i>
                </span>
                <span
                    class="icon is-right is-small"
                    :class="{ 'clear-icon': $isModel(this.value) }"
                    v-if="modelRightIcon !== undefined"
                    @click="onClearClicked"
                >
                    <i :class="modelRightIcon"></i>
                </span>
                <div
                    v-show="showResults"
                    class="autocomplete-results"
                >
                    <ul class="box autocomplete-list">
                        <li
                            class="autocomplete-item"
                            :class="{ active: selectedIndex === index }"
                            :style="{ 'text-align': textAlign }"
                            :key="index"
                            v-for="(item, index) in results"
                            @click="onResultClicked(item)"
                            @mouseover="onMouseOver(index)"
                        >
                            <slot
                                :item="item"
                                :index="index"
                                name="item"
                            >
                                {{ itemLabel(item) }}
                            </slot>
                        </li>
                    </ul>
                </div>
                <p
                    class="help is-danger"
                    v-for="error in errors"
                    :key="error"
                >
                    {{ error }}
                </p>
            </div>
            <div
                class="control"
                v-if="hasAddons"
            >
                <router-link
                    v-if="editRouteObject !== undefined"
                    class="button is-info is-small"
                    target="_blank"
                    :to="editRouteObject"
                >
                    <span class="icon is-small">
                        <i class="fas fa-edit"></i>
                    </span>
                </router-link>
                <router-link
                    v-else-if="addRouteObject !== undefined"
                    class="button is-info is-small"
                    target="_blank"
                    :to="addRouteObject"
                >
                    <span class="icon is-small">
                        <i class="fas fa-plus"></i>
                    </span>
                </router-link>
                <a
                    v-else-if="rightButtonIcon"
                    class="button is-primary is-small"
                    :disabled="readonly"
                    @click="onRightButtonClicked"
                >
                    <span class="icon is-small">
                        <i :class="rightButtonIcon"></i>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

import debounce from 'lodash.debounce'

export default {
    name: 'T3ModelField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        placeholder: String,
        readonly: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        endpoint: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        minchars: {
            type: Number,
            default: 1,
        },
        maxResults: {
            type: Number,
            default: 10,
        },
        rightButtonIcon: {
            type: String,
            default: undefined,
        },
        textAlign: {
            type: String,
            default: 'left',
        },
        editRoute: String,
        addRoute: String,
        routeParamName: {
            type: String,
            default: 'pk',
        },
        itemLabel: {
            type: Function,
            default: (item) => (item ? item.label : ''),
        },
        isRounded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        text: '',
        results: [],
        selectedIndex: undefined,
        active: false,
        external: false,
    }),
    computed: {
        modelInputClasses() {
            let result = []
            if (this.inputClasses !== undefined) {
                result.push(this.inputClasses)
            }
            if (this.isRounded) {
                result.push('is-rounded')
            }
            return result
        },
        iconClasses() {
            let classes = []
            if (this.leftIcon) {
                classes.push('has-icons-left')
            }
            if (this.modelRightIcon) {
                classes.push('has-icons-right')
            }
            return classes
        },
        hasAddons() {
            if (this.editRouteObject !== undefined) {
                return true
            }
            if (this.addRouteObject !== undefined) {
                return true
            }
            return this.rightButtonIcon !== undefined
        },
        modelRightIcon() {
            if (this.$isModel(this.value)) {
                return 'fas fa-times-circle'
            }
            return this.rightIcon
        },
        showResults: function () {
            return this.active && this.results.length > 0
        },
        addRouteObject() {
            if (this.addRoute && !this.$isModel(this.value)) {
                return {
                    name: this.addRoute,
                }
            }
            return undefined
        },
        editRouteObject() {
            if (this.editRoute && this.$isModel(this.value)) {
                const params = {}
                params[this.routeParamName] = this.value.pk
                return {
                    name: this.editRoute,
                    params: params,
                }
            }
            return undefined
        },
    },
    watch: {
        value(newval) {
            if (!newval) {
                this.text = ''
            } else {
                this.text = this.itemLabel(newval)
            }
        },
        text: debounce(async function (newval) {
            this.selectedIndex = undefined
            if (this.$isModel(this.value)) {
                if (newval && this.itemLabel(this.value) !== newval) {
                    this.$emit('input', null)
                } else if ((newval?.length || 0) < this.minchars) {
                    this.$emit('input', null)
                } else {
                    this.active = true
                    return
                }
            }
            if ((newval?.length || 0) >= this.minchars) {
                this.results = await this.$request(
                    this.endpoint,
                    {
                        criteria: newval,
                        filters: this.filters,
                        max_ocurrences: this.maxResults,
                    },
                    undefined,
                    true
                )
                this.active = true
            }
        }, 400),
    },
    methods: {
        selectItem(item) {
            this.selectedIndex = undefined
            this.results = []
            this.active = false
            this.$emit('input', item)
            this.$emit('itemActivated', item)
        },
        onResultClicked(result) {
            this.selectItem(result)
        },
        onBlur() {
            if (this.selectedIndex === undefined) {
                this.results = []
                this.active = false
            }
        },
        onMouseOver(index) {
            this.selectedIndex = index
        },
        onKeyUpPressed() {
            if (this.selectedIndex === undefined || this.selectedIndex === 0) {
                this.selectedIndex = this.results.length - 1
            } else {
                this.selectedIndex--
            }
        },
        onKeyDownPressed() {
            if (
                this.selectedIndex === undefined ||
                this.selectedIndex === this.results.length - 1
            ) {
                this.selectedIndex = 0
            } else {
                this.selectedIndex++
            }
        },
        onKeyEnterPressed() {
            if (this.showResults) {
                if (this.selectedIndex !== undefined) {
                    this.selectItem(this.results[this.selectedIndex])
                }
            } else if (this.$isModel(this.value)) {
                this.$emit('keyup.enter')
            }
        },
        onKeyEscPressed() {
            this.active = false
            if (!this.showResults) {
                this.$emit('keyup.esc')
            }
        },
        onClickOutside() {
            this.selectedIndex = undefined
            this.active = false
            this.results = []
        },
        onRightButtonClicked() {
            this.$emit('rightButtonClicked')
        },
        onClearClicked() {
            this.text = ''
            this.$emit('input', null)
        },
        clear() {
            this.text = ''
        },
    },
    created() {
        if (this.$isModel(this.value)) {
            this.text = this.itemLabel(this.value)
        }
    },
}
</script>

<style scoped>
.box {
    background: white;
}

.autocomplete-results {
    position: absolute;
    width: 100%;
    z-index: 100;
}

.autocomplete-list {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid silver;
    background: white;
}

.autocomplete-item {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    margin-left: 0;
    margin-bottom: 0;
    font-size: small;
    list-style-type: none;
    background: white;
}

.active {
    color: white;
    font-weight: bold;
    background-color: #ea621f;
    opacity: 0.5;
    cursor: pointer;
}

.clear-icon {
    cursor: pointer;
    pointer-events: initial !important;
}

.extra {
    position: absolute;
    display: block;
    right: 1rem;
    top: 0;
    z-index: 9;
}
</style>
