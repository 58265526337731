<template>
    <div class="is-unselectable">
        <router-link :to="route">
            <div
                class="icon-box hvr-outline-out"
                id="icon"
            >
                <span class="icon is-large fa-2x">
                    <i :class="icon"></i>
                </span>
            </div>
        </router-link>
        <router-link :to="route">
            <div class="card">
                <header>
                    <p class="card-header-title">{{ label }}</p>
                </header>
                <div class="card-content">
                    <div class="columns">
                        <div
                            class="column is-6"
                            v-if="leftConcept !== undefined"
                        >
                            <div class="left-concept">
                                <div>
                                    {{ leftConcept }}
                                </div>
                                <div
                                    class="left-value"
                                    v-if="leftValue !== undefined"
                                >
                                    {{ leftValue }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="column is-6"
                            v-if="rightConcept !== undefined"
                        >
                            <div class="right-concept">
                                <div>
                                    {{ rightConcept }}
                                </div>
                                <div
                                    class="right-value"
                                    v-if="rightValue !== undefined"
                                >
                                    {{ rightValue }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        route: {
            type: Object,
            required: true,
        },
        leftConcept: String,
        leftValue: String,
        rightConcept: String,
        rightValue: String,
    },
}
</script>

<style scoped>
.card {
    text-align: end;
    border-radius: 2px;
    margin-bottom: 15px;
    cursor: pointer;
}

.card-header-title {
    color: #ea621f;
    padding-top: 25px;
}

#icon {
    position: relative;
    top: 20px;
    left: 20px;
    background: #ea621f;
    width: 70px;
    height: 60px;
    text-align: center;
    z-index: 1;
    box-shadow: 5px 5px 20px 3px #b5b5b5, 0 0 0 1px #dbdbdb;
    border-radius: 3px;
    cursor: pointer;
}

.icon-box .icon {
    position: relative !important;
    top: 10px !important;
    color: white !important;
}

.card-content {
    padding-top: 7px;
    padding-bottom: 19px;
}

.left-concept {
    text-align: left;
    font-size: 12pt;
}

.right-concept {
    font-size: 12pt;
    text-align: right;
}

.left-value {
    text-align: left !important;
    font-size: 10pt;
    text-shadow: 0px 2px 3px grey;
}

.right-value {
    text-align: right;
    font-size: 10pt;
    font-weight: bold;
    text-shadow: 0px 2px 3px grey;
}
</style>
