<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ labelText }}
        </label>

        <div
            :class="{
                control: true,
                'is-expanded': expanded,
            }"
        >
            <div
                :class="{
                    select: true,
                    'is-small': true,
                    'is-danger': hasErrors,
                    'is-fullwidth': expanded,
                }"
            >
                <select
                    v-bind="$attrs"
                    :disabled="readonly"
                    v-model="choice"
                >
                    <template v-if="isModel">
                        <option
                            v-for="opt in $selectedChoices"
                            :key="opt.pk"
                            :value="opt"
                        >
                            {{ opt.label }}
                        </option>
                    </template>

                    <template v-else>
                        <option
                            v-for="[value, label] in $selectedChoices"
                            :key="value"
                            :value="value"
                        >
                            {{ label }}
                        </option>
                    </template>
                </select>
            </div>
        </div>
        <p
            class="help is-danger"
            v-for="error in errors"
            :key="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3ChoicesField',
    extends: Field,
    props: {
        value: {
            type: [String, Number, Boolean, Object],
            default: null,
        },
        choices: {
            type: [Array, String],
            required: true,
        },
        isBoolean: {
            type: Boolean,
            default: false,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        isModel: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        choice: {
            get() {
                if (
                    this.value &&
                    !this.isModel &&
                    typeof this.value === 'object'
                ) {
                    return this.value.pk
                }
                return this.value
            },
            set(newval) {
                let value = newval
                if (this.isBoolean) {
                    if (newval === 'true' || newval === true) {
                        value = true
                    } else if (newval === 'false' || newval === false) {
                        value = false
                    } else {
                        value = null
                    }
                }
                if (this.nullable && value === null) {
                    this.$emit('input', null)
                } else {
                    this.$emit('input', value)
                }
            },
        },
        $selectedChoices() {
            if (Array.isArray(this.choices)) {
                return this.choices
            } else if (typeof this.choices === 'string') {
                return this.choicesList(this.choices)
            }
            return null
        },
    },
}
</script>
