import Vue from 'vue'

import AutocompleteField from './AutocompleteField.vue'
import CheckField from './CheckField.vue'
import ChoicesField from './ChoicesField.vue'
import ChoicesTagsField from './ChoicesTagsField.vue'
import DateField from './DateField.vue'
import DateRangeField from './DateRangeField.vue'
import DateTimeField from './DateTimeField.vue'
import DocumentField from './DocumentField.vue'
import ModelField from './ModelField.vue'
import MultipleModelField from './MultipleModelField.vue'
import NumberField from './NumberField.vue'
import RatingField from './RatingField.vue'
import RichTextAreaField from './RichTextAreaField.vue'
import SearchBarField from './SearchBarField.vue'
import SelectToggleField from './SelectToggleField.vue'
import TagsField from './TagsField.vue'
import TextAreaField from './TextAreaField.vue'
import TextField from './TextField.vue'
import TimeField from './TimeField.vue'
import ToggleField from './ToggleField.vue'
import UploadButton from './UploadButton.vue'
import UploadField from './UploadField.vue'
import SwitchField from '@/components/controls/SwitchField.vue'

Vue.component('T3AutocompleteField', AutocompleteField)
Vue.component('T3CheckField', CheckField)
Vue.component('T3ChoicesField', ChoicesField)
Vue.component('T3ChoicesTagsField', ChoicesTagsField)
Vue.component('T3DateField', DateField)
Vue.component('T3DateRangeField', DateRangeField)
Vue.component('T3DateTimeField', DateTimeField)
Vue.component('T3DocumentField', DocumentField)
Vue.component('T3ModelField', ModelField)
Vue.component('T3MultipleModelField', MultipleModelField)
Vue.component('T3NumberField', NumberField)
Vue.component('T3RatingField', RatingField)
Vue.component('T3RichTextAreaField', RichTextAreaField)
Vue.component('T3SearchBarField', SearchBarField)
Vue.component('T3SelectToggleField', SelectToggleField)
Vue.component('T3TagsField', TagsField)
Vue.component('T3TextAreaField', TextAreaField)
Vue.component('T3TextField', TextField)
Vue.component('T3TimeField', TimeField)
Vue.component('T3ToggleField', ToggleField)
Vue.component('T3UploadButton', UploadButton)
Vue.component('T3UploadField', UploadField)
Vue.component('T3SwitchField', SwitchField)

import HotelField from './variations/HotelField.vue'
import I18NTextAreaField from './I18NTextAreaField.vue'
import I18NTextField from './I18NTextField.vue'
import LangComboField from './variations/LangComboField.vue'
import LangTabField from './variations/LangTabField.vue'
import LocationAutocomplete from './variations/LocationAutocomplete.vue'
import LocationField from './variations/LocationField.vue'
import MonthsChoicesField from './variations/MonthsChoicesField.vue'
import StaffField from './variations/StaffField.vue'
import YearsChoicesField from './variations/YearsChoicesField.vue'

Vue.component('T3HotelField', HotelField)
Vue.component('T3I18NTextAreaField', I18NTextAreaField)
Vue.component('T3I18NTextField', I18NTextField)
Vue.component('T3LangComboField', LangComboField)
Vue.component('T3LangTabField', LangTabField)
Vue.component('T3LocationAutocomplete', LocationAutocomplete)
Vue.component('T3LocationField', LocationField)
Vue.component('T3MonthsChoicesField', MonthsChoicesField)
Vue.component('T3StaffField', StaffField)
Vue.component('T3YearsChoicesField', YearsChoicesField)
