<template>
    <T3Modal
        ref="modal"
        :title="title"
    >
        <div
            ref="selectionMap"
            style="width: 600px; height: 300px"
        ></div>
        <template slot="footer">
            <div class="buttons">
                <T3Button
                    label="Cancel"
                    @clicked="onCancelClicked"
                />
                <T3Button
                    label="Select"
                    role="primary"
                    :disabled="isSelectButtonDisabled"
                    @clicked="onSelectClicked"
                />
            </div>
        </template>
    </T3Modal>
</template>

<script>
/* import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js' */

/* import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder' */
/* import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css' */

export default {
    props: {
        title: {
            type: String,
            default: 'Select Location',
        },
    },
    data: () => ({
        initialized: false,
        map: null,
        geocoder: null,
        selected: undefined,
    }),
    computed: {
        isSelectButtonDisabled() {
            return this.selected === undefined
        },
    },
    methods: {
        open(nearBy, country) {
            this.$refs.modal.show()
            this.$nextTick(() => {
                if (!this.initialized) {
                    mapboxgl.accessToken =
                        'pk.eyJ1IjoicmJlY2VycmExOTg2IiwiYSI6ImNrcmc1ZjdpdjJnbW4ydXA4dGphMnM1YmcifQ.glixiBFMjFElyoQyPcYb5Q'

                    this.map = new mapboxgl.Map({
                        container: this.$refs.selectionMap,
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: [nearBy.lng, nearBy.lat],
                        zoom: nearBy.name !== undefined ? 16 : 10,
                    })
                    this.map.addControl(new mapboxgl.NavigationControl())

                    this.geocoder = new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        mapboxgl: mapboxgl,
                        proximity: {
                            longitude: nearBy.lng,
                            latitude: nearBy.lat,
                        },
                        countries: country,
                        types: 'region,district,place,locality,neighborhood,poi',
                    })

                    this.geocoder.on('result', this.onGeocoderResultClicked)
                    this.map.addControl(this.geocoder, 'top-left')

                    this.map.on('click', 'poi-label', this.onClicked)

                    this.initialized = true
                } else {
                    this.geocoder.setProximity({
                        longitude: nearBy.lng,
                        latitude: nearBy.lat,
                    })

                    if (country !== undefined) {
                        this.geocoder.setCountries(country)
                    }

                    this.map.setZoom(nearBy.name !== undefined ? 16 : 9)
                    this.map.panTo([nearBy.lng, nearBy.lat], { animate: false })
                }
            })

            this.$nextTick(() => {
                const coderInput = document.getElementsByClassName(
                    'mapboxgl-ctrl-geocoder--input'
                )[0]

                if (nearBy.name !== undefined) {
                    this.selected = nearBy
                    coderInput.value = nearBy.name
                } else {
                    this.selected = undefined
                    this.geocoder.clear()
                }
            })
        },
        onGeocoderResultClicked({ result }) {
            const name = result.text
            let lng = result.center[0]
            let lat = result.center[1]
            if (result.bbox !== undefined) {
                lng = (result.bbox[0] + result.bbox[2]) / 2.0
                lat = (result.bbox[1] + result.bbox[3]) / 2.0
            }
            this.selected = {
                name,
                lng,
                lat,
            }
        },
        onSelectClicked() {
            this.$emit('placeSelected', this.selected)
            this.$refs.modal.hide()
        },
        onCancelClicked() {
            this.$refs.modal.hide()
        },
    },
}
</script>
